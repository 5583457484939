import Vue from "vue";
import VueRouter from "vue-router";
import Home from "./components/Home";
import Game from "./components/Game";
import Final from "./components/Final";
import Sunucu from "./components/Sunucu";
import Master from "./components/Master";
import MasterPlay from "./components/MasterPlay";
import MasterList from "./components/MasterList";
import ChooseCard from "./components/ChooseCard";
import Login from "./components/Login_bayrakli";
import Teknoted_addGame from "./components/Teknoted_addGame";
import Teknoted_addUsers from "./components/Teknoted_addUsers";
import CodeUret from "./components/CodeUret";
import AllList from "./components/AllList";
import Ekran from "./components/Ekran";
import Ekran_game from "./components/Ekran_game";
import Ekran_final from "./components/Ekran_final";
//import store from './store/index'

Vue.use(VueRouter);

const routes = [
  //website
  { name: "Home", component: Home, path: "/" },
  { name: "ChooseCard", component: ChooseCard, path: "/chooseCard" },
  { name: "Login", component: Login, path: "/login" },
  { name: "CodeUret", component: CodeUret, path: "/codeuret" },

  {
    name: "Teknoted_addGame",
    component: Teknoted_addGame,
    path: "/teknoted_addGame",
  },
  {
    name: "Teknoted_addUsers",
    component: Teknoted_addUsers,
    path: "/teknoted_addUsers",
  },

  { name: "Demo", component: Login, path: "/demo", meta: { company: "demo" } },
  {
    name: "Demo_ChooseCard",
    component: ChooseCard,
    path: "/demo/choose",
    meta: { company: "demo" },
  },
  {
    name: "Demo_Game",
    component: Game,
    path: "/demo/game",
    meta: { company: "demo" },
  },
  {
    name: "Demo_Master",
    component: Master,
    path: "/demo/master",
    meta: { company: "demo" },
  },
  {
    name: "Demo_MasterPlay",
    component: MasterPlay,
    path: "/demo/master/play",
    meta: { company: "demo" },
  },
  {
    name: "Demo_Final",
    component: Final,
    path: "/demo/final",
    meta: { company: "demo" },
  },
  {
    name: "Demo_Sunucu",
    component: Sunucu,
    path: "/demo/sunucu",
    meta: { company: "demo" },
  },
  {
    name: "Demo_MasterList",
    component: MasterList,
    path: "/demo/master/list",
    meta: { company: "demo" },
  },

  {
    name: "bosch",
    component: Login,
    path: "/bosch",
    meta: { company: "bosch" },
  },
  {
    name: "bosch_ChooseCard",
    component: ChooseCard,
    path: "/bosch/choose",
    meta: { company: "bosch" },
  },
  {
    name: "bosch_Game",
    component: Game,
    path: "/bosch/game",
    meta: { company: "bosch" },
  },
  {
    name: "bosch_Master",
    component: Master,
    path: "/bosch/master",
    meta: { company: "bosch" },
  },
  {
    name: "bosch_MasterPlay",
    component: MasterPlay,
    path: "/bosch/master/play",
    meta: { company: "bosch" },
  },
  {
    name: "bosch_Final",
    component: Final,
    path: "/bosch/final",
    meta: { company: "bosch" },
  },
  {
    name: "bosch_Sunucu",
    component: Sunucu,
    path: "/bosch/sunucu",
    meta: { company: "bosch" },
  },
  {
    name: "bosch_MasterList",
    component: MasterList,
    path: "/bosch/master/list",
    meta: { company: "bosch" },
  },
  {
    name: "bosch_AllList",
    component: AllList,
    path: "/bosch/allList",
    meta: { company: "bosch" },
  },
  {
    name: "bosch_Ekran",
    component: Ekran,
    path: "/bosch/ekran",
    meta: { company: "bosch" },
  },
  {
    name: "bosch_Ekran_game",
    component: Ekran_game,
    path: "/bosch/ekran_game",
    meta: { company: "bosch" },
  },
  {
    name: "bosch_Ekran_final",
    component: Ekran_final,
    path: "/bosch/ekran_final",
    meta: { company: "bosch" },
  },

  {
    name: "aydem",
    component: Login,
    path: "/aydem",
    meta: { company: "aydem" },
  },
  {
    name: "aydem_ChooseCard",
    component: ChooseCard,
    path: "/aydem/choose",
    meta: { company: "aydem" },
  },
  {
    name: "aydem_Game",
    component: Game,
    path: "/aydem/game",
    meta: { company: "aydem" },
  },
  {
    name: "aydem_Master",
    component: Master,
    path: "/aydem/master",
    meta: { company: "aydem" },
  },
  {
    name: "aydem_MasterPlay",
    component: MasterPlay,
    path: "/aydem/master/play",
    meta: { company: "aydem" },
  },
  {
    name: "aydem_Final",
    component: Final,
    path: "/aydem/final",
    meta: { company: "aydem" },
  },
  {
    name: "aydem_Sunucu",
    component: Sunucu,
    path: "/aydem/sunucu",
    meta: { company: "aydem" },
  },
  {
    name: "aydem_MasterList",
    component: MasterList,
    path: "/aydem/master/list",
    meta: { company: "aydem" },
  },
  {
    name: "aydem_AllList",
    component: AllList,
    path: "/aydem/allList",
    meta: { company: "aydem" },
  },
  {
    name: "aydem_Ekran",
    component: Ekran,
    path: "/aydem/ekran",
    meta: { company: "aydem" },
  },
  {
    name: "aydem_Ekran_game",
    component: Ekran_game,
    path: "/aydem/ekran_game",
    meta: { company: "aydem" },
  },
  {
    name: "aydem_Ekran_final",
    component: Ekran_final,
    path: "/aydem/ekran_final",
    meta: { company: "aydem" },
  },

  { name: "atu", component: Login, path: "/atu", meta: { company: "atu" } },
  {
    name: "atu_ChooseCard",
    component: ChooseCard,
    path: "/atu/choose",
    meta: { company: "atu" },
  },
  {
    name: "atu_Game",
    component: Game,
    path: "/atu/game",
    meta: { company: "atu" },
  },
  {
    name: "atu_Master",
    component: Master,
    path: "/atu/master",
    meta: { company: "atu" },
  },
  {
    name: "atu_MasterPlay",
    component: MasterPlay,
    path: "/atu/master/play",
    meta: { company: "atu" },
  },
  {
    name: "atu_Final",
    component: Final,
    path: "/atu/final",
    meta: { company: "atu" },
  },
  {
    name: "atu_Sunucu",
    component: Sunucu,
    path: "/atu/sunucu",
    meta: { company: "atu" },
  },
  {
    name: "atu_MasterList",
    component: MasterList,
    path: "/atu/master/list",
    meta: { company: "atu" },
  },
  {
    name: "atu_AllList",
    component: AllList,
    path: "/atu/allList",
    meta: { company: "atu" },
  },
  {
    name: "atu_Ekran",
    component: Ekran,
    path: "/atu/ekran",
    meta: { company: "atu" },
  },
  {
    name: "atu_Ekran_game",
    component: Ekran_game,
    path: "/atu/ekran_game",
    meta: { company: "atu" },
  },
  {
    name: "atu_Ekran_final",
    component: Ekran_final,
    path: "/atu/ekran_final",
    meta: { company: "atu" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: "",
  routes,
});

//const loginRoute = '/login';

/*
router.beforeEach(async (to, from, next) => {

    const user_group = store.getters.getUserGroup;


    if( to.meta.permission != "all" && user_group != to.meta.permission){
        next({ path: loginRoute });
    }else{
        next();
    }
    

  
    
    
});
  */

export default router;
