<template>
    <div class="container mt-5">

        
            <label for="text-password">Şifre:</label>
            <b-form-input type="password" v-model="password" aria-describedby="password-help-block"></b-form-input>
            
            <div class="mt-2 text-right">
                <b-button  @click="login" variant="primary">Giriş</b-button>
            </div>

    </div>
</template>

<style lang="scss">
@import '~@/styles/master.css';
</style>


<script>

import { initializeApp } from "firebase/app";
import { getDatabase,ref,get,child } from "firebase/database";

const config = {
    apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
    authDomain: "tombalabiz.firebaseapp.com",
    databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
    projectId: "tombalabiz",
    storageBucket: "tombalabiz.appspot.com",
    messagingSenderId: "225198155208",
    appId: "1:225198155208:web:e697d08f91c323dec5ccfd"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);


export default {

    data(){
        return {
            company: "",
            password: ""
        }
    },

    methods:{
        getCompany(){
            this.company = this.$route.meta.company
        },
        login(){

            let nRef = this.company+"/adminPassword";

            get(child(dbRef, nRef)).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                if(this.password == data){
                    console.log("giriş yap");

                    //document.cookie = "name=yT5h21dH";
                    this.$store.commit('setIsAdmin', "1");
                    setTimeout(() => this.$router.push('/'+this.company+'/master/play'), 150);
                    
                    

                }else{
                this.showToast("Lütfen şifrenizi kontrol ediniz!");
                }
            } 
            }).catch((error) => {
                console.log(error);
            });

        },
        showToast(gelen){

            this.$bvToast.toast(gelen, {
                title: "Hata!",
                autoHideDelay: 3000,
                appendToast: true,
                variant: 'danger',
                solid: true,
            })
            
        }
    },
    mounted () {
        this.getCompany()
    },
    
    
}
</script>