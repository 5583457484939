<template>
    <div class="container mt-5">

        <div class="row">
            <div class="col-12">

                <div>Toplam Aktif: {{toplamActive}}</div>

                <b-card title="En Yakın Kullanıcılar">
                    <ul id="example-1">
                        <li v-for="(item,index) in users" :key="index">
                            {{ item.name }} - 


                                <span :class="classBul(i.opened)" v-for="i in item.numbers" :key="i.key">{{i.number}}</span>
                            

                        </li>
                    </ul>
                </b-card>

            </div>


        </div>

    </div>
</template>

<style lang="scss">
@import '~@/styles/master.css';
</style>


<script>


import { initializeApp } from "firebase/app";
import { getDatabase,ref,child,get } from "firebase/database";

const config = {
    apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
    authDomain: "tombalabiz.firebaseapp.com",
    databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
    projectId: "tombalabiz",
    storageBucket: "tombalabiz.appspot.com",
    messagingSenderId: "225198155208",
    appId: "1:225198155208:web:e697d08f91c323dec5ccfd"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);

export default {

    data(){
        return {
            company: "",
            secret: "",
            numbers: [],
            users:[],

            isAdmin : 0,
            toplamActive : 0
        }
    },

    methods:{

        orderedItems(items) {
            items.sort((a, b) => {
                return b.tane - a.tane;
            });  
            return items;
        },
        getCompany(){
            this.company = this.$route.meta.company;
            this.isAdmin = this.$store.getters.getIsAdmin;
            if(this.isAdmin == "0"){
                this.$router.push('/'+this.company+'/master');
            }
        },

        classBul(gelen){

            let res = "badge badge-secondary mr-2";

            if(gelen == "1"){
                res = "badge badge-success mr-2";
            }

            return res
        }


    },

    mounted () {
        this.getCompany();

        let nRefusers = this.company+"/users";
        get(child(dbRef, nRefusers)).then((snapshot) => {
        if (snapshot.exists()) {


            snapshot.forEach(e => {

                    
                    let tane = 0;

                    if(e.val().active == "1"){
                        this.toplamActive ++;
                    }


                    if(e.val().allNumbers != null){
                        
                        
                        e.val().allNumbers.forEach(tt => {
                            if(tt.opened == "1"){
                                tane ++;
                            }
                        });

                        let temp = {
                            name: e.val().name,
                            tane: tane,
                            numbers: e.val().allNumbers

                        };
                        this.users.push(temp);
                    }

                    
                
            });



        } 
        }).catch((error) => {
            console.log(error);
        }).then(()=> {
            this.users = this.orderedItems(this.users);
        }).then(()=>{
            this.users = this.users.splice(0, 10);
        });




    },

    watch: {
       
        

    },


    

}
</script>