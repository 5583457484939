<template>
  <div>
    <div class="container cont_kisa">
      <div class="text-center" style="padding-top: 40px">
        <img :src="tombala_logo" width="220" />
      </div>
      <div class="f_26 font_semibold mt-4">{{ text_login_hos }}</div>
      <div class="mt-3">{{ text_login_sub }}</div>
      <div class="mt-1">{{ text_login_sub2 }}</div>

      <div class="row mt-3">
        <div class="col-12">
          <div id="logindiv">
            <div class="f14 primaryColor">Adınız Soyadınız</div>

            <div class="border1 border1_color mt-2">
              <input
                v-model="ad"
                type="text"
                class="form-control form_altcizgi primary_color"
                aria-describedby="button-addon2"
              />
            </div>

            <div class="f14 primaryColor mt-3">{{ text_giris_kodunuz }}</div>

            <div class="border1 border1_color mt-2">
              <div class="">
                <input
                  v-model="email"
                  type="text"
                  class="form-control form_altcizgi border1_color primary_color"
                  aria-describedby="button-addon2"
                />
              </div>
            </div>
            <button
              class="btn btn-light formSendBtn form-control mt-4"
              type="button"
              @click="loginYap"
              id="button-addon2"
            >
              Giriş
            </button>
          </div>
        </div>
      </div>

      <b-modal ref="my-modal" hide-footer size="lg">
        <div class="d-block color_siyah">
          <keep-alive>
            <component :is="currentTerm"></component>
          </keep-alive>
        </div>
      </b-modal>
    </div>
    <div class="text-center login_logo">
      <img width="160" :src="firma_logo" alt="" />
    </div>
  </div>
</template>

<script>
import bos from "@/assets/checkbox-bos_beyaz_kare.png";
import dolu from "@/assets/checkbox-dolu_beyaz_kare.png";
import bos_siyah from "@/assets/checkbox-bos_siyah.png";
import dolu_siyah from "@/assets/checkbox-dolu_siyah.png";
import turkish from "@/assets/tr-flag.png";
import english from "@/assets/en-flag.png";

import { initializeApp } from "firebase/app";
import { getDatabase, get, child, ref, set } from "firebase/database";
//import { getDatabase,ref,set,onValue,get,child } from "firebase/database";

const config = {
  apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
  authDomain: "tombalabiz.firebaseapp.com",
  databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
  projectId: "tombalabiz",
  storageBucket: "tombalabiz.appspot.com",
  messagingSenderId: "225198155208",
  appId: "1:225198155208:web:e697d08f91c323dec5ccfd",
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);

const myCss = (mss) => import("@/styles/" + mss + ".css");

export default {
  components: {},

  data() {
    return {
      componentToDisplay: null,
      checkbox: true,
      checkbox2: true,
      checkbox_img: dolu,
      checkbox_img2: dolu,
      language: "tr",
      flag: english,
      company: "",
      ad: "",
      email: "",
      checkbox_type: "beyaz",
      tombala_logo: "",
      firma_logo: "",
      text_login_hos: "",
      text_login_sub: "",
      text_login_sub2: "",
      text_giris_kodunuz: "",
      text_sozlesme: "",
      text_sozlesme2: "",
      text_login_toast_title: "",
      text_login_toast_katilim: "",
      text_login_toast_email: "",
    };
  },

  computed: {
    currentTerm: function () {
      if (this.componentToDisplay) {
        return () => import(`@/translate/${this.componentToDisplay}`);
      }
      return null;
    },
  },

  async created() {
    let res = await import("@/translate/" + this.$route.meta.company + ".json");
    let res2 = JSON.stringify(res);
    let translate = JSON.parse(res2);

    let translate_icerik = translate.tr;

    this.text_login_hos = translate_icerik.login_hos;
    this.text_login_sub = translate_icerik.login_sub;
    this.text_login_sub2 = translate_icerik.login_sub2;
    this.text_giris_kodunuz = translate_icerik.giris_kodunuz;
    this.text_sozlesme = translate_icerik.sozlesme;
    this.text_sozlesme2 = translate_icerik.sozlesme2;
    this.text_login_toast_title = translate_icerik.login_toast_title;
    this.text_login_toast_katilim = translate_icerik.login_toast_katilim;
    this.text_login_toast_email = translate_icerik.login_toast_email;
    this.tombala_logo = translate_icerik.tombala_logo;
    this.firma_logo = translate_icerik.firma_logo;
    this.checkbox_type = translate_icerik.checkbox_type;

    if (this.checkbox_type == "siyah") {
      this.checkbox_img = dolu_siyah;
      this.checkbox_img2 = dolu_siyah;
    } else {
      this.checkbox_img = dolu;
      this.checkbox_img2 = dolu;
    }
  },

  methods: {
    getCompany() {
      this.company = this.$route.meta.company;
      console.log(this.$route.meta.company);
      myCss(this.$route.meta.company);
    },
    showModal() {
      this.componentToDisplay = "terms_" + this.$route.meta.company + "_tr";
      this.$refs["my-modal"].show();
    },
    showModal2() {
      this.componentToDisplay = "terms_teleperformance_rules.vue";
      this.$refs["my-modal"].show();
    },
    okudum() {
      if (this.checkbox) {
        this.checkbox = false;
        if (this.checkbox_type == "siyah") {
          this.checkbox_img = bos_siyah;
        } else {
          this.checkbox_img = bos;
        }
      } else {
        this.checkbox = true;
        if (this.checkbox_type == "siyah") {
          this.checkbox_img = dolu_siyah;
        } else {
          this.checkbox_img = dolu;
        }
      }
    },
    okudum2() {
      if (this.checkbox2) {
        this.checkbox2 = false;
        if (this.checkbox_type == "siyah") {
          this.checkbox_img2 = bos_siyah;
        } else {
          this.checkbox_img2 = bos;
        }
      } else {
        this.checkbox2 = true;
        if (this.checkbox_type == "siyah") {
          this.checkbox_img2 = dolu_siyah;
        } else {
          this.checkbox_img2 = dolu;
        }
      }
    },

    loginYap() {
      this.ad = this.ad.trim();
      this.email = this.email.trim();
      if (this.checkbox === false) {
        this.showToast(this.text_login_toast_katilim);
      } else {
        if (this.email == "" || this.ad == "") {
          this.showToast(this.text_login_toast_email);
        } else {
          let _refListeReady = this.company + "/listeReady";
          get(child(dbRef, _refListeReady))
            .then((snapshot) => {
              if (snapshot.val() == "1") {
                this.girisYap();
              } else {
                this.uyeOlustur();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }
    },

    showToast(gelen) {
      this.$bvToast.toast(gelen, {
        title: this.text_login_toast_title,
        autoHideDelay: 3000,
        appendToast: true,
        variant: "danger",
        solid: true,
      });
    },
    changeLang(gelen) {
      if (gelen == "tr") {
        this.flag = turkish;
      } else {
        this.flag = english;
      }
      this.language = gelen;
      this.$store.commit("setLanguage", gelen);
      window.location.reload();
    },

    girisYap() {
      let _refName = this.company + "/users";

      get(child(dbRef, _refName))
        .then((snapshot) => {
          if (snapshot.exists()) {
            //console.log(snapshot.val());
            let userFound = false;

            snapshot.forEach((e) => {
              let dat = e.val();

              if (dat.email == this.email.toLowerCase()) {
                //bulundu
                userFound = true;

                this.$store.commit("setUid", e.key);
                this.$store.commit("setEmail", this.email.toLowerCase());
                this.$store.commit("setName", dat.name);

                set(ref(db, this.company + "/users/" + e.key + "/active"), 1);

                if (typeof dat.allNumbers === "undefined") {
                  this.$router.push("/" + this.company + "/choose");
                } else {
                  this.$router.push("/" + this.company + "/game");
                }
              }
            });

            if (!userFound) {
              this.showToast(this.text_login_toast_email);
            }
          } else {
            this.showToast(this.text_login_toast_email);
          }
        })
        .catch((error) => {
          console.log(error);
          this.showToast(this.text_login_toast_email);
        });
    },

    uyeOlustur() {
      let _refName = this.company + "/users";

      get(child(dbRef, _refName))
        .then((snapshot) => {
          if (snapshot.exists()) {
            let userFound = false;
            snapshot.forEach((e) => {
              let dat = e.val();

              if (dat.email == this.email.toLowerCase()) {
                //bulundu
                set(ref(db, this.company + "/users/" + e.key + "/active"), 1);

                userFound = true;
                console.log(dat);

                this.$store.commit("setUid", e.key);
                this.$store.commit("setEmail", dat.email);
                this.$store.commit("setName", dat.name);

                if (typeof dat.allNumbers === "undefined") {
                  this.$router.push("/" + this.company + "/choose");
                } else {
                  this.$router.push("/" + this.company + "/game");
                }
              }
            });

            if (!userFound) {
              //const nn = this.email.split("@");
              const id = this.randName();

              set(ref(db, this.company + "/users/" + id), {
                name: this.ad,
                email: this.email.toLowerCase(),
                active: 1,
                cinko1: 0,
                cinko2: 0,
                tombala: 0,
              });

              this.$store.commit("setUid", id);
              this.$store.commit("setEmail", this.email.toLowerCase());
              this.$store.commit("setName", this.ad);

              this.$router.push("/" + this.company + "/choose");
            }
          } else {
            //const nn = this.email.split("@");
            const id = this.randName();

            set(ref(db, this.company + "/users/" + id), {
              name: this.ad,
              email: this.email.toLowerCase(),
              active: 1,
              cinko1: 0,
              cinko2: 0,
              tombala: 0,
            });

            this.$store.commit("setUid", id);
            this.$store.commit("setEmail", this.email.toLowerCase());
            this.$store.commit("setName", this.ad);

            this.$router.push("/" + this.company + "/choose");
          }
        })
        .catch((error) => {
          console.log(error);
          this.showToast(this.text_login_toast_email);
        });
    },

    randName() {
      var result = "";
      var characters = "abcdefghijklmnopqrstuvwxyz0123456789";
      var charactersLength = characters.length;
      for (var i = 0; i < 12; i++) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
      }
      return result;
    },
  },

  mounted() {
    this.getCompany();

    this.checkbox = true;
    this.checkbox2 = true;

    this.language = this.$store.getters.getLanguage;
    if (this.language == "tr") {
      this.flag = turkish;
    } else {
      this.flag = english;
    }
  },
};
</script>
