<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col-6">
        <div class=" ">
          <a
            href="#"
            @click="finishGame"
            class="btn btn-danger btn-sm float-right"
            >Oyunu Bitir</a
          >

          <a
            href="#"
            v-if="company != 're'"
            @click="reset"
            class="btn btn-sm btn-primary"
            >RESET</a
          >
        </div>

        <div class="clearfix mb-2"></div>

        <b-card>
          <div
            @click="selectItem(item.number)"
            :class="itemClass(item.number)"
            v-for="item in this.numbers"
            :key="item.number"
          >
            {{ item.number }}
          </div>
        </b-card>

        <a
          href="#"
          v-if="ekran_acik == 1"
          @click="ekran_kapat"
          class="btn btn-sm mt-3 btn-success"
          >EKRAN AÇIK</a
        >
        <a
          href="#"
          v-if="ekran_acik == 0"
          @click="ekran_ac"
          class="btn btn-sm mt-3 btn-dark"
          >EKRAN KAPALI</a
        >
      </div>

      <div class="col-3">
        <b-card :title="`1. Çinko - ${cinko1Users.length} kişi`">
          <ol id="example-1">
            <li v-for="item in cinko1Users.slice(0, 4)" :key="item.userId">
              {{ item.userName }}
            </li>
          </ol>
        </b-card>

        <b-card class="mt-3" :title="`2. Çinko - ${cinko2Users.length} kişi`">
          <ol id="example-1">
            <li v-for="item in cinko2Users.slice(0, 4)" :key="item.userId">
              {{ item.userName }}
            </li>
          </ol>
        </b-card>

        <b-card class="mt-3" :title="`Tombala - ${tombalaUsers.length} kişi`">
          <ol id="example-1">
            <li v-for="item in tombalaUsers.slice(0, 1)" :key="item.userId">
              {{ item.userName }}
            </li>
          </ol>
        </b-card>
      </div>

      <div class="col-3">
        <b-card
          v-if="awardLastNumberVar"
          :title="`Şanslı Sayı Ödülü - ${awardLastNumberUsers.length} kişi`"
        >
          <ol id="example-1">
            <li
              v-for="item in awardLastNumberUsers.slice(0, 20)"
              :key="item.userId"
            >
              {{ item.userName }}
            </li>
          </ol>
          <a
            href="#"
            v-if="awardLastNumberStatus == 0"
            @click="startAwardLastNumber"
            class="btn btn-warning btn-sm"
            >Başlat</a
          >
          <a
            href="#"
            v-if="awardLastNumberStatus == 1"
            @click="finishAwardLastNumber"
            class="btn btn-danger btn-sm"
            >Bitir</a
          >
          <div v-if="awardLastNumber != 0" class="f_14 text-muted">
            Seçilen Numara: {{ awardLastNumber }}
          </div>
        </b-card>

        <b-card
          class="mt-3"
          v-if="awardGuessNumberVar"
          :title="`Tahmin Ödülü - ${awardGuessNumberUsers.length} kişi`"
        >
          <ol id="example-1">
            <li
              v-for="item in awardGuessNumberUsers.slice(0, 20)"
              :key="item.userId"
            >
              {{ item.userName }}
            </li>
          </ol>
          <a
            href="#"
            v-if="awardGuessNumberStatus == 0"
            @click="startAwardGuessNumber"
            class="btn btn-warning btn-sm"
            >Başlat</a
          >
          <a
            href="#"
            v-if="awardGuessNumberStatus == 1"
            @click="finishAwardGuessNumber"
            class="btn btn-danger btn-sm"
            >Bitir</a
          >
          <div v-if="awardGuessNumber != 0" class="f_14 text-muted">
            Seçilen Numara: {{ awardGuessNumber }}
          </div>
        </b-card>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
@import "~@/styles/master.css";
</style>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, child, onValue, get } from "firebase/database";

const config = {
  apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
  authDomain: "tombalabiz.firebaseapp.com",
  databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
  projectId: "tombalabiz",
  storageBucket: "tombalabiz.appspot.com",
  messagingSenderId: "225198155208",
  appId: "1:225198155208:web:e697d08f91c323dec5ccfd",
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);

export default {
  data() {
    return {
      company: "",
      secret: "",
      numbers: [],
      selectedNumbers: [],
      cinko1Users: [],
      cinko2Users: [],
      tombalaUsers: [],
      cinko1Users_temp: [],
      cinko2Users_temp: [],
      tombalaUsers_temp: [],
      isAdmin: 0,
      awardLastNumberUsers: [],
      awardLastNumberStatus: 0,
      awardLastNumberVar: 0,
      awardLastNumber: 0,

      awardGuessNumberUsers: [],
      awardGuessNumberStatus: 0,
      awardGuessNumberVar: 0,
      awardGuessNumber: 0,
      ekran_acik: 0,
    };
  },

  methods: {
    ekran_kapat() {
      set(ref(db, this.company + "/ekran_oyun_basla"), 0);
      this.ekran_acik = 0;
    },
    ekran_ac() {
      set(ref(db, this.company + "/ekran_oyun_basla"), 1);
      this.ekran_acik = 1;
    },

    reset() {
      if (confirm("Oyunu resetlemek istediğinize emin misiniz?")) {
        let nRef1 = this.company + "/cinko1Users";
        set(ref(db, nRef1), null);
        let nRef1_1 = this.company + "/cinko2Users";
        set(ref(db, nRef1_1), null);
        let nRef1_2 = this.company + "/tombalaUsers";
        set(ref(db, nRef1_2), null);

        let nRef2 = this.company + "/awardGuessNumber";
        set(ref(db, nRef2), 0);

        let nRef3 = this.company + "/awardGuessNumberStatus";
        set(ref(db, nRef3), 0);

        let nRef4 = this.company + "/awardGuessNumberUsers";
        set(ref(db, nRef4), null);

        let nRef5 = this.company + "/awardLastNumber";
        set(ref(db, nRef5), 0);

        let nRef6 = this.company + "/awardLastNumberStatus";
        set(ref(db, nRef6), 0);

        let nRef7 = this.company + "/awardLastNumberUsers";
        set(ref(db, nRef7), null);

        let nRef8 = this.company + "/lastOpen";
        set(ref(db, nRef8), 0);

        let nRef9 = this.company + "/opens";
        set(ref(db, nRef9), null);

        let nRef10 = this.company + "/status";
        set(ref(db, nRef10), 1);

        let nRef11 = this.company + "/lastCinko1";
        set(ref(db, nRef11), 0);

        let nRef12 = this.company + "/lastCinko2";
        set(ref(db, nRef12), 0);

        let nRef13 = this.company + "/lastTombala";
        set(ref(db, nRef13), 0);

        let nRef14 = this.company + "/totalOpen";
        set(ref(db, nRef14), 0);

        let nRef15 = this.company + "/users";
        set(ref(db, nRef15), null);

        let nRef16 = this.company + "/ekran_oyun_basla";
        set(ref(db, nRef16), 0);
      }
    },
    orderedItems(items) {
      items.sort((a, b) => {
        return a.times - b.times;
      });
      return items;
    },
    getCompany() {
      this.company = this.$route.meta.company;
      this.isAdmin = this.$store.getters.getIsAdmin;
      if (this.isAdmin == "0") {
        this.$router.push("/" + this.company + "/master");
      }
    },

    showToast(gelen) {
      this.$bvToast.toast(gelen, {
        title: "Hata!",
        autoHideDelay: 3000,
        appendToast: true,
        variant: "danger",
        solid: true,
      });
    },

    selectItem(number) {
      if (!this.selectedNumbers.includes(number)) {
        if (confirm("Seçmek istediğinize emin misiniz?")) {
          this.selectedNumbers.push(number);
          set(ref(db, this.company + "/lastOpen"), number);
          set(ref(db, this.company + "/opens"), this.selectedNumbers);

          if (this.awardLastNumberStatus == 1) {
            set(ref(db, this.company + "/awardLastNumber"), number);
            this.awardLastNumber = number;
          }

          if (this.awardGuessNumberStatus == 1) {
            set(ref(db, this.company + "/awardGuessNumber"), number);
            this.awardGuessNumber = number;
          }
        }
      }
    },

    itemClass(number) {
      let res = "number_box round_box font_medium";
      if (this.selectedNumbers.includes(number)) {
        res = "number_box_selected round_box font_medium";
      }
      return res;
    },

    findUserNameCinko1() {
      this.cinko1Users = this.orderedItems(this.cinko1Users);

      this.cinko1Users.forEach((e) => {
        let userId = e.userId;

        if (e.userName == "") {
          let nRef = this.company + "/users/" + userId + "/name";
          get(child(dbRef, nRef))
            .then((snapshot) => {
              if (snapshot.exists()) {
                const data = snapshot.val();
                e.userName = data;
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    },
    findUserNameCinko2() {
      this.cinko2Users = this.orderedItems(this.cinko2Users);

      this.cinko2Users.forEach((e) => {
        let userId = e.userId;
        if (e.userName == "") {
          let nRef = this.company + "/users/" + userId + "/name";
          get(child(dbRef, nRef))
            .then((snapshot) => {
              if (snapshot.exists()) {
                const data = snapshot.val();
                e.userName = data;
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    },
    findUserNameTombala() {
      this.tombalaUsers = this.orderedItems(this.tombalaUsers);
      this.tombalaUsers.forEach((e) => {
        let userId = e.userId;
        if (e.userName == "") {
          let nRef = this.company + "/users/" + userId + "/name";
          get(child(dbRef, nRef))
            .then((snapshot) => {
              if (snapshot.exists()) {
                const data = snapshot.val();
                e.userName = data;
              }
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    },

    checkIfCinko1Contains(userId) {
      let res = false;
      this.cinko1Users.forEach((element) => {
        if (element.userId == userId) {
          res = true;
        }
      });
      return res;
    },

    checkIfCinko2Contains(userId) {
      let res = false;
      this.cinko2Users.forEach((element) => {
        if (element.userId == userId) {
          res = true;
        }
      });
      return res;
    },

    checkIfTombalaContains(userId) {
      let res = false;
      this.tombalaUsers.forEach((element) => {
        if (element.userId == userId) {
          res = true;
        }
      });
      return res;
    },

    checkIfLastNumberContains(userId) {
      let res = false;
      this.awardLastNumberUsers.forEach((element) => {
        if (element.userId == userId) {
          res = true;
        }
      });
      return res;
    },

    checkIfGuessNumberContains(userId) {
      let res = false;
      this.awardGuessNumberUsers.forEach((element) => {
        if (element.userId == userId) {
          res = true;
        }
      });
      return res;
    },

    create_Cinko1() {
      this.cinko1Users = [];
      this.cinko1Users_temp.forEach((e) => {
        if (
          !this.checkIfCinko2Contains(e.userId) &&
          !this.checkIfTombalaContains(e.userId)
        ) {
          this.cinko1Users.push(e);
        }
      });
    },
    create_Cinko2() {
      this.cinko2Users = [];
      this.cinko2Users_temp.forEach((e) => {
        if (!this.checkIfTombalaContains(e.userId)) {
          this.cinko2Users.push(e);
        }
      });
    },
    create_Tombala() {
      this.tombalaUsers = [];

      this.tombalaUsers_temp.forEach((e) => {
        this.tombalaUsers.push(e);
      });
    },

    finishGame() {
      if (confirm("Oyunu bitirmek istediğinize emin misiniz?")) {
        set(ref(db, this.company + "/status"), 2);
      }
    },
  },

  mounted() {
    this.getCompany();

    const refek = ref(db, this.company + "/ekran_oyun_basla");
    onValue(refek, (snapshot) => {
      const data = snapshot.val();
      this.ekran_acik = data;
    });

    const refOpens = ref(db, this.company + "/opens");
    onValue(refOpens, (snapshot) => {
      const data = snapshot.val();
      if (data != null) {
        this.selectedNumbers = data;
      }
    });
    for (let index = 1; index < 100; index++) {
      let temp = {
        number: index,
        selected: 0,
      };
      this.numbers.push(temp);
    }

    const refTombala = ref(db, this.company + "/tombalaUsers");
    onValue(refTombala, (snapshot) => {
      let _tmp = [];

      snapshot.forEach((e) => {
        let temp = {
          userId: e.key,
          userName: "",
          times: e.val(),
        };

        _tmp.push(temp);
      });

      this.tombalaUsers_temp = _tmp;
      this.create_Tombala();
      this.findUserNameTombala();
    });

    const refCinko2 = ref(db, this.company + "/cinko2Users");
    onValue(refCinko2, (snapshot) => {
      let _tmp = [];

      snapshot.forEach((e) => {
        let temp = {
          userId: e.key,
          userName: "",
          times: e.val(),
        };
        _tmp.push(temp);
      });

      this.cinko2Users_temp = _tmp;
      this.create_Cinko2();
      this.findUserNameCinko2();
    });

    const refCinko1 = ref(db, this.company + "/cinko1Users");
    onValue(refCinko1, (snapshot) => {
      let _tmp = [];
      snapshot.forEach((e) => {
        let temp = {
          userId: e.key,
          userName: "",
          times: e.val(),
        };
        _tmp.push(temp);
      });

      this.cinko1Users_temp = _tmp;
      this.create_Cinko1();
      this.findUserNameCinko1();
    });
  },

  watch: {
    gameStatus: function (val) {
      if (val == 2) {
        this.$router.push("/" + this.company + "/ekran_final");
      }
    },

    cinko1Users_temp: function (val, oldVal) {
      if (val != oldVal) {
        this.create_Tombala();
        this.create_Cinko2();
        this.create_Cinko1();
      }
    },

    /*************  ✨ Codeium Command ⭐  *************/
    /**
     * Called when cinko2Users_temp changes. If the change is real (i.e. not just a
     * reactive change), then recreate Tombala, Cinko2, and Cinko1.
     * @param {object} val - The new value of cinko2Users_temp.
     * @param {object} oldVal - The old value of cinko2Users_temp.
     */
    /******  fb4bbfc0-997e-4abd-b00c-d871149437fb  *******/
    cinko2Users_temp: function (val, oldVal) {
      if (val != oldVal) {
        this.create_Tombala();
        this.create_Cinko2();
        this.create_Cinko1();
      }
    },

    tombalaUsers_temp: function (val, oldVal) {
      if (val != oldVal) {
        this.create_Tombala();
        this.create_Cinko2();
        this.create_Cinko1();
      }
    },

    cinko1Users: function (val, oldVal) {
      if (val != oldVal) {
        this.findUserNameCinko1();
        this.findUserNameCinko2();
        this.findUserNameTombala();
      }
    },
    cinko2Users: function (val, oldVal) {
      if (val != oldVal) {
        this.findUserNameCinko1();
        this.findUserNameCinko2();
        this.findUserNameTombala();
      }
    },
    tombalaUsers: function (val, oldVal) {
      if (val != oldVal) {
        this.findUserNameCinko1();
        this.findUserNameCinko2();
        this.findUserNameTombala();
      }
    },
  },
};
</script>
