
<template>
<div class="container cont_kisa mt-5">
    
    
    <b-row class="my-1">
        <b-col sm="4">
            <label for="input-small">Oyun:</label>
        </b-col>
        <b-col sm="8">
        <b-form-input v-model="name" id="input-small" size="sm" ></b-form-input>
        </b-col>
    </b-row>

    <b-row class="my-1">
        <b-col sm="4">
            <label for="input-small">Admin Passsword:</label>
        </b-col>
        <b-col sm="8">
        <b-form-input v-model="Adminpassword" id="input-small" size="sm" ></b-form-input>
        </b-col>
    </b-row>

    <b-row class="my-1">
        <b-col sm="6">
            <label for="input-small">Liste var mı?: (0 yok, 1 var)</label>
        </b-col>
        <b-col sm="6">
        <b-form-input v-model="liste" id="input-small" size="sm" ></b-form-input>
        </b-col>
    </b-row>

    <b-row class="my-1">
        <b-col sm="7">
            <label for="input-small">Şanslı Sayı Ödülü var mı?: (0 yok, 1 var)</label>
        </b-col>
        <b-col sm="5">
        <b-form-input v-model="awardLastNumber" id="input-small" size="sm" ></b-form-input>
        </b-col>
    </b-row>

    <b-row class="my-1">
        <b-col sm="7">
            <label for="input-small">Tahmin Ödülü var mı?: (0 yok, 1 var)</label>
        </b-col>
        <b-col sm="5">
        <b-form-input v-model="awardGuessNumber" id="input-small" size="sm" ></b-form-input>
        </b-col>
    </b-row>



    <b-row class="my-1">
        <b-col class="text-right" sm="12">
            <a href="#" @click="addGame" class="btn btn-primary">EKLE</a>
        </b-col>
    </b-row>
</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,set,ref } from "firebase/database";


const config = {
    apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
    authDomain: "tombalabiz.firebaseapp.com",
    databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
    projectId: "tombalabiz",
    storageBucket: "tombalabiz.appspot.com",
    messagingSenderId: "225198155208",
    appId: "1:225198155208:web:e697d08f91c323dec5ccfd"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
//const dbRef = ref(db);


export default {

    components:{
        //HeaderBar
    },

    data(){
        return {
            name: "",
            Adminpassword: "",
            liste: "",
            awardLastNumber: "",
            awardGuessNumber: "",
        }
    },

    methods:{
        addGame(){
            console.log(this.name);


            set(ref(db, this.name), {
                adminPassword: this.Adminpassword,
                gameId: this.randName(),
                lastOpen : 0,
                lastCinko1 : 0,
                lastCinko2 : 0,
                lastTombala : 0,
                listeReady : parseInt(this.liste),
                status: 1,
                totalOpen : 0,
                awardLastNumberVar : parseInt(this.awardLastNumber),
                awardLastNumberStatus : 0,
                awardLastNumber : 0,

                awardGuessNumberVar: parseInt(this.awardGuessNumber),
                awardGuessNumberStatus : 0,
                awardGuessNumber : 0,

            });


        },
        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 12; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        }
    },

    mounted () {
        //this.getWeatherData()
    },
};


</script>

