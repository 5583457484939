<template>
  <div class="p-4">
    <div class="text-center f_60 font_semibold" style="margin-top: 110px">
      Hoş geldiniz!
    </div>
    <div class="font_semibold text-center f_34 mt-4">
      Telefonunuzun kamerası ile aşağıdaki QR kodu okutarak <br />
      giriş yapabilirsiniz.
    </div>
    <div class="text-center mt-5">
      <img v-if="company == 'bosch'" src="../assets/bosch-qr.png" width="400" />
      <img v-if="company == 'aydem'" src="../assets/aydem-qr.png" width="400" />
    </div>
    <div class="text-center url mt-4 font_semibold f_30">
      tombala.biz/{{ company }}
    </div>
    <div
      class="position-absolute f_32 font_semibold"
      style="right: 20px; bottom: 20px"
    >
      Mutlu, başarılı ve sağlıklı bir yıl dileriz.
    </div>
    <img
      :src="firma_logo"
      width="200"
      class="position-absolute"
      style="left: 20px; bottom: 20px"
    />
  </div>
</template>

<style lang="scss">
@import "~@/styles/home.css";
</style>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";

const config = {
  apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
  authDomain: "tombalabiz.firebaseapp.com",
  databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
  projectId: "tombalabiz",
  storageBucket: "tombalabiz.appspot.com",
  messagingSenderId: "225198155208",
  appId: "1:225198155208:web:e697d08f91c323dec5ccfd",
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);

//const myCss = () => import("@/styles/ekran.css");
const myCss = (mss) => import("@/styles/ekran_" + mss + ".css");

export default {
  data() {
    return {
      ekran_oyun_basla: 0,
      firma_logo: "",
      company: "",
    };
  },

  async created() {
    let res = await import("@/translate/" + this.$route.meta.company + ".json");
    let res2 = JSON.stringify(res);
    let translate = JSON.parse(res2);

    console.log(this.language);

    let translate_icerik = translate.tr;

    this.firma_logo = translate_icerik.firma_logo;
  },

  methods: {
    loadCss() {
      //myCss();
    },
    getCompany() {
      this.company = this.$route.meta.company;
      console.log("this.$route.meta.company");
      console.log(this.$route.meta.company);
      myCss(this.$route.meta.company);

      const refOpens = ref(db, this.$route.meta.company + "/ekran_oyun_basla");
      onValue(refOpens, (snapshot) => {
        const data = snapshot.val();
        console.log(data);
        this.ekran_oyun_basla = data;

        if (data == 1) {
          this.$router.push("/" + this.$route.meta.company + "/ekran_game");
        }
      });
    },
  },

  mounted() {
    this.loadCss();
    this.getCompany();
  },

  watch: {},
};
</script>
