
<template>
<div class="container cont_kisa color_white  pb-5">
    <div class="text-center">

        <img src="@/assets/tombala-logo-beyaz.png" width="280" class=" mt-5 ">


        <h1 class=" text-center mt-4 font_semibold f_24">Kurum içi canlı tombala oyunu</h1>

        <div class="text-center mt-5">
            <a href="https://forms.gle/mFBqHoPLFHHWGCMs6" target="_blank" class="btn btn-light btn-lg but font_semibold px-4">Sunum ve Demo Talep Et</a>
            
        </div>
        <div class="text-center mt_30">
            <img src="@/assets/web-phone.png" width="320" alt="tombala cards">
        </div>

        <div class="mt-4 f_14">powered by</div>
        <div class="mt-1"><img src="@/assets/teknoted_logo.png" width="150" alt="teknoted logo"></div>
        <div class="mt-4">Teknoted Bilişim Ltd. Şti.</div>
        <div class="mt-2">info@teknoted.com</div>
        <div class="mt-2 ">Cevizli Mah. Tansel Cad. Yeşil Sok. No:4<br>Adakule Residence D:76<br>Maltepe, İstanbul</div>
        <div class="mt-5 aciklama">Online tombala oyunu ile kurum içi canlı etkinliklerinize eğlence katabilirsiniz. Web tabanlı yapısı ile uygulama indirmeye gerek kalmadan ister 10, ister 15.000 kişi aynı anda tombala oynayabilir. Marka kurumsal kimliğine uygun şekilde %100 özelleştirilebilen arayüzü ve çoklu dil desteği ile tüm mobil ve masaüstü cihazlardan kolayca oynanabilir. Klasik tombala oyunu baz alınarak geliştirilen yapısıyla tüm katılımcılar tarafından kolayca anlaşılabilir. 2020’de yirmiden fazla şirket içi etkinlikte tercih edilen Tombala Online yeni ve geliştirilmiş versiyonu ile karşınızda.</div>



    </div>
</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>


const myCss = () => import('@/styles/home.css');

export default {

    components:{
        //HeaderBar
    },

    data(){
        return {
        
        }
    },

    methods:{
        loadCss(){
            myCss();
        },
    },

    mounted () {
        this.loadCss();
    },

    
};


</script>

