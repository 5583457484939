
<template>
<div class="container cont_kisa  mt-5">
    
    <div v-for="i in sayilar" :key="i">
        {{i}}
    </div>
    
    
</div>
</template>



<script>




export default {

    components:{
        //HeaderBar
    },

    data(){
        return {
            name: "",
            basla: 12500,
            sayilar: []

        }
    },

    methods:{

        randSayi(){
            for (let index = 0; index < 120; index++) {

                let rr = Math.floor(Math.random() * 100);
                let yeniSayi = this.basla +  parseInt(rr) + 1;
                this.basla = yeniSayi;

                this.sayilar.push(yeniSayi);

                
            }
        }

    },

    mounted () {
        this.randSayi()
    },
};


</script>

