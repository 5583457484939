<template>
  <div>
    <div class="container dw640 pb_80">
      <div class="text-center" style="padding-top: 20px">
        <img :src="tombala_logo" width="260" />
      </div>

      <div class="f_26 font_semibold text-center mt-5">
        {{ text_tebrikler }}
      </div>
      <div class="f_18 text-center mt-1">{{ text_final_tamamlandi }}</div>
      <div class="f_18 text-center">{{ text_final_saglik }}</div>

      <div class="f_22 font_semibold text-center mt-5">
        {{ text_final_tombala }}
      </div>
      <b-list-group class="mt-2">
        <b-list-group-item
          v-for="item in tombalaUsers.slice(0, 1)"
          :key="item.userId"
          class="backgroundTrans border_color_final"
        >
          {{ item.userName }}
        </b-list-group-item>
      </b-list-group>

      <div
        v-if="company != 'incimaxion'"
        class="f_22 font_semibold text-center mt-5"
      >
        {{ text_final_2cinko }}
      </div>
      <b-list-group v-if="company != 'incimaxion'" class="mt-2">
        <b-list-group-item
          v-for="item in cinko2Users.slice(0, 3)"
          :key="item.userId"
          class="backgroundTrans border_color_final"
        >
          {{ item.userName }}
        </b-list-group-item>
      </b-list-group>

      <div
        v-if="company != 'incimaxion'"
        class="f_22 font_semibold text-center mt-5"
      >
        {{ text_final_1cinko }}
      </div>
      <b-list-group v-if="company != 'incimaxion'" class="mt-2">
        <b-list-group-item
          v-for="item in cinko1Users.slice(0, 3)"
          :key="item.userId"
          class="backgroundTrans border_color_final"
        >
          {{ item.userName }}
        </b-list-group-item>
      </b-list-group>
    </div>

    <div class="text-center mb-5">
      <img width="180" :src="firma_logo" alt="" />
    </div>
  </div>
</template>

<script>
import turkish from "@/assets/tr-flag.png";
import english from "@/assets/en-flag.png";

import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, child } from "firebase/database";

const config = {
  apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
  authDomain: "tombalabiz.firebaseapp.com",
  databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
  projectId: "tombalabiz",
  storageBucket: "tombalabiz.appspot.com",
  messagingSenderId: "225198155208",
  appId: "1:225198155208:web:e697d08f91c323dec5ccfd",
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);

const myCss = (mss) => import("@/styles/" + mss + ".css");

export default {
  data() {
    return {
      language: "tr",
      flag: turkish,
      company: "",
      email: "",
      uid: "",
      name: "",
      tombalaUsers: [],
      cinko1Users: [],
      cinko2Users: [],

      cinko1Users_temp: [],
      cinko2Users_temp: [],
      tombalaUsers_temp: [],

      text_tebrikler: "",
      tombala_logo: "",
      firma_logo: "",

      text_final_tamamlandi: "",
      text_final_saglik: "",
      text_final_tombala: "",
      text_final_2cinko: "",
      text_final_1cinko: "",
    };
  },

  async created() {
    let res = await import("@/translate/" + this.$route.meta.company + ".json");
    let res2 = JSON.stringify(res);
    let translate = JSON.parse(res2);

    let translate_icerik = translate.tr;

    this.text_tebrikler = translate_icerik.tebrikler;
    this.tombala_logo = translate_icerik.tombala_logo;
    this.firma_logo = translate_icerik.firma_logo;

    this.text_final_tamamlandi = translate_icerik.final_tamamlandi;
    this.text_final_saglik = translate_icerik.final_saglik;
    this.text_final_tombala = translate_icerik.final_tombala;
    this.text_final_2cinko = translate_icerik.final_2cinko;
    this.text_final_1cinko = translate_icerik.final_1cinko;
  },

  methods: {
    getCompany() {
      this.company = this.$route.meta.company;
      console.log(this.$route.meta.company);
      myCss(this.$route.meta.company);

      this.get_tombalaUsers();
    },

    get_tombalaUsers() {
      let nRef3 = this.company + "/tombalaUsers";
      get(child(dbRef, nRef3))
        .then((snapshot) => {
          this.tombalaUsers = [];
          snapshot.forEach((e) => {
            let temp = {
              userId: e.key,
              userName: this.findUserNameTombala(e.key),
              times: e.val(),
            };
            this.tombalaUsers.push(temp);
          });
        })
        .then(() => {
          this.tombalaUsers = this.orderedItems(this.tombalaUsers);
        })
        .then(() => {
          this.get_Cinko2Users();
        });
    },

    get_Cinko2Users() {
      let nRef2 = this.company + "/cinko2Users";
      get(child(dbRef, nRef2))
        .then((snapshot) => {
          this.cinko2Users = [];
          snapshot.forEach((e) => {
            let temp = {
              userId: e.key,
              userName: this.findUserNameCinko2(e.key),
              times: e.val(),
            };
            if (!this.checkIfTombalaContains(e.key)) {
              this.cinko2Users.push(temp);
            }
          });
        })
        .then(() => {
          this.cinko2Users = this.orderedItems(this.cinko2Users);
        })
        .then(() => {
          this.get_Cinko1Users();
        });
    },

    get_Cinko1Users() {
      let nRef = this.company + "/cinko1Users";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          this.cinko1Users = [];
          snapshot.forEach((e) => {
            let temp = {
              userId: e.key,
              userName: this.findUserNameCinko1(e.key),
              times: e.val(),
            };
            if (
              !this.checkIfCinko2Contains(e.key) &&
              !this.checkIfTombalaContains(e.key)
            ) {
              this.cinko1Users.push(temp);
            }
          });
        })
        .then(() => {
          this.cinko1Users = this.orderedItems(this.cinko1Users);
        });
    },

    orderedItems(items) {
      items.sort((a, b) => {
        return a.times - b.times;
      });
      return items;
    },

    findUserNameCinko1(userId) {
      let nRef = this.company + "/users/" + userId + "/name";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();

            this.cinko1Users.forEach((e) => {
              if (e.userId == userId) {
                e.userName = data;
              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    findUserNameCinko2(userId) {
      let nRef = this.company + "/users/" + userId + "/name";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();

            this.cinko2Users.forEach((e) => {
              if (e.userId == userId) {
                e.userName = data;
              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    findUserNameTombala(userId) {
      let nRef = this.company + "/users/" + userId + "/name";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();

            this.tombalaUsers.forEach((e) => {
              if (e.userId == userId) {
                e.userName = data;
              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    changeLang(gelen) {
      if (gelen == "tr") {
        this.flag = turkish;
      } else {
        this.flag = english;
      }
      this.language = gelen;
      this.$store.commit("setLanguage", gelen);
      window.location.reload();
    },

    checkIfCinko1Contains(userId) {
      let res = false;
      this.cinko1Users.forEach((element) => {
        if (element.userId == userId) {
          res = true;
        }
      });
      return res;
    },

    checkIfCinko2Contains(userId) {
      let res = false;
      this.cinko2Users.forEach((element) => {
        if (element.userId == userId) {
          res = true;
        }
      });
      return res;
    },

    checkIfTombalaContains(userId) {
      let res = false;
      this.tombalaUsers.forEach((element) => {
        if (element.userId == userId) {
          res = true;
        }
      });
      return res;
    },
  },

  mounted() {
    this.getCompany();

    this.language = this.$store.getters.getLanguage;
    this.email = this.$store.getters.getEmail;
    this.uid = this.$store.getters.getUid;
    this.name = this.$store.getters.getName;

    if (this.language == "tr") {
      this.flag = turkish;
    } else {
      this.flag = english;
    }
  },
};
</script>
