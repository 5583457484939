
<template>
<div class="container cont_kisa color_white mt-5">
    
    
    <b-row class="my-1">
        <b-col sm="4">
            <label for="input-small">Oyun:</label>
        </b-col>
        <b-col sm="8">
        <b-form-input v-model="name" id="input-small" size="sm" ></b-form-input>
        </b-col>
    </b-row>



    <b-row class="my-1">
        <b-col sm="4">
            <label for="input-small">Dosya:</label>
        </b-col>
        <b-col sm="8">
        <b-form-file v-model="file2" class="mt-3" plain></b-form-file>
        </b-col>
    </b-row>

   


    <b-row class="my-1">
        <b-col class="text-right" sm="12">
            <a href="#" @click="readFile" class="btn btn-primary">EKLE</a>
        </b-col>
    </b-row>
</div>
</template>


<style lang="scss">
@import '~@/styles/home.css';
</style>

<script>

import { initializeApp } from "firebase/app";
import { getDatabase,set,ref } from "firebase/database";


const config = {
    apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
    authDomain: "tombalabiz.firebaseapp.com",
    databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
    projectId: "tombalabiz",
    storageBucket: "tombalabiz.appspot.com",
    messagingSenderId: "225198155208",
    appId: "1:225198155208:web:e697d08f91c323dec5ccfd"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);

//const dbRef = ref(db);


export default {

    components:{
        //HeaderBar
    },

    data(){
        return {
            name: "",
            file2: null,
            fileinput: ""
        }
    },

    methods:{
        addUsers(){
            
            
            var lines = this.fileinput.split("\n");

            while( typeof lines[0] !== "undefined" ){
                var line = lines.shift();
                //var split = line.split(',');
                //console.log(line);
                
                //let email = split[1].replace(/\r?\n|\r/g, "");
                //let email = split[1].replace(/\r?\n|\r/g, "").toLowerCase();
                let email = line.replace(/\r?\n|\r/g, "").toLowerCase();
                //let name = split[0].replace(/\r?\n|\r/g, "");

                let sp = email.split('@');
                let name = sp[0];

                let numbers = [];

                set(ref(db, this.name+"/users/"+this.randName()), {
                    name: name,
                    email: email,
                    active:0,
                    cinko:0,
                    tombala:0,
                    allNumbers:numbers
                });

            }

        },

        readFile: function() {
            const scope = this
            var promiseVar = new Promise( (resolve, reject) => {
                try {
                    var reader = new FileReader();
                    var file = this.file2;
                    reader.readAsText(file);
                    reader.onload = function(e) {
                        scope.fileinput = e.target.result
                        resolve('complete');
                    };
                } catch (error) {
                    console.error(error);
                    reject("There must be an error");
                }
            });
            // let updateds = await Promise.all(promiseVar);
            promiseVar.then(data=>{
                scope.afterRead();
                console.log(data);
            })
        },

        afterRead:function(){
            this.addUsers();
        },

        randName(){
            var result           = '';
            var characters       = 'abcdefghijklmnopqrstuvwxyz0123456789';
            var charactersLength = characters.length;
            for ( var i = 0; i < 15; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * 
            charactersLength));
            }
            return result;
        }
        
    },

    mounted () {
        //this.getWeatherData()
    },
};


</script>

