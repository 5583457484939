<template>
  <div class="p-4">
    <div class="text-center f_60 font_semibold" style="margin-top: 80px">
      Tebrikler!
    </div>

    <div
      class="border_fin font_semibold text-center mx-auto mt-5"
      style="width: 580px"
    >
      <div class="color_fin p-3 f_48">Tombala Yapan</div>
      <div class="p-3 f_34">{{ tombalaUsers[0]?.userName }}</div>
    </div>

    <div
      class="border_fin font_semibold text-center mx-auto mt-5"
      style="width: 1400px"
    >
      <div class="color_fin p-3 f_40">İkinci Çinko Yapan İlk 3</div>

      <div class="p-3 f_30 d-flex">
        <div class="flex-fill text-center">{{ cinko2Users[0]?.userName }}</div>
        <div class="flex-fill text-center border_xx_card">
          {{ cinko2Users[1]?.userName }}
        </div>
        <div class="flex-fill text-center">
          {{ cinko2Users[2]?.userName }}
        </div>
      </div>
    </div>
    <div
      class="border_fin font_semibold text-center mx-auto mt-5"
      style="width: 1400px"
    >
      <div class="color_fin p-3 f_40">Birinci Çinko Yapan İlk 3</div>

      <div class="p-3 f_30 d-flex">
        <div class="flex-fill text-center">{{ cinko1Users[0]?.userName }}</div>
        <div class="flex-fill text-center border_xx_card">
          {{ cinko1Users[1]?.userName }}
        </div>
        <div class="flex-fill text-center">
          {{ cinko1Users[2]?.userName }}
        </div>
      </div>
    </div>

    <div
      class="position-absolute f_32 font_semibold"
      style="right: 20px; bottom: 20px"
    >
      Mutlu, başarılı ve sağlıklı bir yıl dileriz.
    </div>
    <img
      :src="firma_logo"
      width="200"
      class="position-absolute"
      style="left: 20px; bottom: 20px"
    />
  </div>
</template>

<style lang="scss">
@import "~@/styles/home.css";
</style>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, ref, get, child, onValue } from "firebase/database";

const config = {
  apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
  authDomain: "tombalabiz.firebaseapp.com",
  databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
  projectId: "tombalabiz",
  storageBucket: "tombalabiz.appspot.com",
  messagingSenderId: "225198155208",
  appId: "1:225198155208:web:e697d08f91c323dec5ccfd",
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);

const myCss = (mss) => import("@/styles/ekran_" + mss + ".css");

export default {
  data() {
    return {
      ekran_oyun_basla: 0,
      tombalaUsers: [],
      cinko1Users: [],
      cinko2Users: [],

      cinko1Users_temp: [],
      cinko2Users_temp: [],
      tombalaUsers_temp: [],
      firma_logo: "",
      company: "",
    };
  },

  async created() {
    let res = await import("@/translate/" + this.$route.meta.company + ".json");
    let res2 = JSON.stringify(res);
    let translate = JSON.parse(res2);

    console.log(this.language);

    let translate_icerik = translate.tr;

    this.firma_logo = translate_icerik.firma_logo;
  },

  methods: {
    loadCss() {
      //myCss();
    },

    getCompany() {
      this.company = this.$route.meta.company;
      console.log("this.$route.meta.company");
      console.log(this.$route.meta.company);
      myCss(this.$route.meta.company);

      const refOpens = ref(db, this.$route.meta.company + "/ekran_oyun_basla");
      onValue(refOpens, (snapshot) => {
        const data = snapshot.val();
        console.log(data);
        this.ekran_oyun_basla = data;
      });

      this.get_tombalaUsers();
    },

    get_tombalaUsers() {
      let nRef3 = this.company + "/tombalaUsers";
      get(child(dbRef, nRef3))
        .then((snapshot) => {
          this.tombalaUsers = [];
          snapshot.forEach((e) => {
            let temp = {
              userId: e.key,
              userName: this.findUserNameTombala(e.key),
              times: e.val(),
            };
            this.tombalaUsers.push(temp);
          });
        })
        .then(() => {
          this.tombalaUsers = this.orderedItems(this.tombalaUsers);
        })
        .then(() => {
          this.get_Cinko2Users();
        });
    },

    get_Cinko2Users() {
      let nRef2 = this.company + "/cinko2Users";
      get(child(dbRef, nRef2))
        .then((snapshot) => {
          this.cinko2Users = [];
          snapshot.forEach((e) => {
            let temp = {
              userId: e.key,
              userName: this.findUserNameCinko2(e.key),
              times: e.val(),
            };
            if (!this.checkIfTombalaContains(e.key)) {
              this.cinko2Users.push(temp);
            }
          });
        })
        .then(() => {
          this.cinko2Users = this.orderedItems(this.cinko2Users);
        })
        .then(() => {
          this.get_Cinko1Users();
        });
    },

    get_Cinko1Users() {
      let nRef = this.company + "/cinko1Users";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          this.cinko1Users = [];
          snapshot.forEach((e) => {
            let temp = {
              userId: e.key,
              userName: this.findUserNameCinko1(e.key),
              times: e.val(),
            };
            if (
              !this.checkIfCinko2Contains(e.key) &&
              !this.checkIfTombalaContains(e.key)
            ) {
              this.cinko1Users.push(temp);
            }
          });
        })
        .then(() => {
          this.cinko1Users = this.orderedItems(this.cinko1Users);
        })
        .then(() => {
          console.log(this.cinko1Users);
        });
    },

    orderedItems(items) {
      items.sort((a, b) => {
        return a.times - b.times;
      });
      return items;
    },

    findUserNameCinko1(userId) {
      let nRef = this.company + "/users/" + userId + "/name";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();

            this.cinko1Users.forEach((e) => {
              if (e.userId == userId) {
                e.userName = data;
              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    findUserNameCinko2(userId) {
      let nRef = this.company + "/users/" + userId + "/name";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();

            this.cinko2Users.forEach((e) => {
              if (e.userId == userId) {
                e.userName = data;
              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    findUserNameTombala(userId) {
      let nRef = this.company + "/users/" + userId + "/name";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();

            this.tombalaUsers.forEach((e) => {
              if (e.userId == userId) {
                e.userName = data;
              }
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    checkIfCinko1Contains(userId) {
      let res = false;
      this.cinko1Users.forEach((element) => {
        if (element.userId == userId) {
          res = true;
        }
      });
      return res;
    },

    checkIfCinko2Contains(userId) {
      let res = false;
      this.cinko2Users.forEach((element) => {
        if (element.userId == userId) {
          res = true;
        }
      });
      return res;
    },

    checkIfTombalaContains(userId) {
      let res = false;
      this.tombalaUsers.forEach((element) => {
        if (element.userId == userId) {
          res = true;
        }
      });
      return res;
    },
  },

  mounted() {
    this.loadCss();
    this.getCompany();
  },

  watch: {},
};
</script>
