<template>
  <div class="p-4">
    <b-overlay
      :show="showOverlay"
      variant="dark"
      opacity="0.90"
      z-index="1"
      no-wrap
    ></b-overlay>
    <div
      v-if="showOverlay"
      class="overlayText text-center overlayIc font_semibold"
    >
      <img src="../assets/Rectangle24.png" width="160" />
      <div class="tebrikler_font_size">TOMBALA!</div>
    </div>

    <div class="text-center mt-5 font_semibold f_40">Son Çekilen Sayı</div>
    <div
      ref="last"
      class="lastN lastNumber_bos mt-4 mb-4 text-center font_semibold son_cekilen_color"
    >
      {{ last_number }}
    </div>

    <div
      class="text-center font_semibold f_32 pt-5 position-relative"
      style="margin-top: 90px"
    >
      <div
        class="position-absolute"
        style="left: 0; top: 0; z-index: 0; right: 0"
      >
        <div
          class="mx-auto"
          style="background-color: #fff; color: #13182c; width: 300px"
        >
          Çekilen Sayılar
        </div>
      </div>
      <div
        class="position-absolute"
        style="
          bottom: 20px;
          z-index: -1;
          left: 500px;
          right: 500px;
          border-bottom: 2px;
          border-style: solid;
          border-color: #fff;
        "
      ></div>
    </div>

    <div
      class="mt-5 d-flex align-items-start flex-wrap justify-content-center"
      style="padding-left: 130px; padding-right: 130px"
    >
      <div
        v-for="item in numbersAll"
        :key="item.number"
        :class="open_class_bul(item.dolu)"
      >
        {{ item.number }}
      </div>
    </div>

    <div
      class="position-absolute f_32 font_semibold"
      style="right: 20px; bottom: 20px"
    >
      Mutlu, başarılı ve sağlıklı bir yıl dileriz.
    </div>
    <img
      :src="firma_logo"
      width="200"
      class="position-absolute"
      style="left: 20px; bottom: 20px"
    />
  </div>
</template>

<style lang="scss">
@import "~@/styles/home.css";
</style>

<script>
import { initializeApp } from "firebase/app";
import { getDatabase, onValue, ref } from "firebase/database";
import confetti from "canvas-confetti";

const config = {
  apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
  authDomain: "tombalabiz.firebaseapp.com",
  databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
  projectId: "tombalabiz",
  storageBucket: "tombalabiz.appspot.com",
  messagingSenderId: "225198155208",
  appId: "1:225198155208:web:e697d08f91c323dec5ccfd",
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);

const myCss = (mss) => import("@/styles/ekran_" + mss + ".css");

export default {
  data() {
    return {
      ekran_oyun_basla: 0,
      last_number: 0,
      numbers: [],
      numbersAll: [],
      numbersOpen: [],
      isTombala: 0,
      showOverlay: false,
      gameStatus: 1,
      firma_logo: "",
      company: "",
    };
  },

  async created() {
    let res = await import("@/translate/" + this.$route.meta.company + ".json");
    let res2 = JSON.stringify(res);
    let translate = JSON.parse(res2);

    console.log(this.language);

    let translate_icerik = translate.tr;

    this.firma_logo = translate_icerik.firma_logo;
  },

  methods: {
    loadCss() {
      //myCss();
    },
    getCompany() {
      this.company = this.$route.meta.company;
      console.log("this.$route.meta.company");
      console.log(this.$route.meta.company);
      myCss(this.$route.meta.company);

      const refOpens = ref(db, this.$route.meta.company + "/ekran_oyun_basla");
      onValue(refOpens, (snapshot) => {
        const data = snapshot.val();
        console.log(data);
        this.ekran_oyun_basla = data;

        if (data == 0) {
          this.$router.push("/" + this.$route.meta.company + "/ekran");
        }
      });

      const refStatus = ref(db, this.$route.meta.company + "/status");
      onValue(refStatus, (snapshot) => {
        const data = snapshot.val();
        this.gameStatus = data;
      });

      const refOpens2 = ref(db, this.$route.meta.company + "/lastOpen");
      onValue(refOpens2, (snapshot) => {
        const data = snapshot.val();

        if (data != 0) {
          this.last_number = data;
          this.$refs["last"].classList.remove("lastNumber_bos");
          this.$refs["last"].classList.add("lastNumber_dolu");
        } else {
          this.$refs["last"].classList.remove("lastNumber_dolu");
          this.$refs["last"].classList.add("lastNumber_bos");
          this.last_number = "";
        }
      });

      const refOpens3 = ref(db, this.$route.meta.company + "/opens");
      onValue(refOpens3, (snapshot) => {
        const data = snapshot.val();

        if (data != null) {
          this.numbersOpen = data;
        }
      });

      for (let index = 1; index < 100; index++) {
        let temp = {
          number: index,
          selected: 0,
        };
        this.numbers.push(temp);
      }

      const refLastTombala = ref(db, this.$route.meta.company + "/lastTombala");
      onValue(refLastTombala, (snapshot) => {
        const data = snapshot.val();
        if (data != 0) {
          this.isTombala = 1;
          this.tombalaDone();
        }
      });
    },

    createAllNumbers() {
      for (let i = 1; i < 100; i++) {
        let dolu = 0;
        if (this.numbersOpen != null) {
          if (this.numbersOpen.includes(i)) {
            dolu = 1;
          }
        }

        let temp = {
          number: i,
          dolu: dolu,
        };

        this.numbersAll.push(temp);
      }
    },

    open_class_bul(gelen) {
      let ret = "numbers_list_bos font_semibold ";
      if (gelen == 1) {
        ret = "numbers_list_dolu font_semibold ";
      }
      return ret;
    },

    animateCSS(element, animation, prefix = "animate__") {
      new Promise((resolve) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);
        node.classList.add(`${prefix}animated`, animationName);
        function handleAnimationEnd(event) {
          event.stopPropagation();
          node.classList.remove(`${prefix}animated`, animationName);
          resolve("Animation ended");
        }
        node.addEventListener("animationend", handleAnimationEnd, {
          once: true,
        });
      });
    },

    randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    },

    tombalaDone() {
      this.showOverlay = true;

      setTimeout(() => this.animateCSS(".overlayIc", "heartBeat"), 50);
      setTimeout(() => this.animateCSS(".overlayIc", "bounceOut"), 10000);
      setTimeout(() => (this.showOverlay = false), 10500);
      setTimeout(() => {
        clearInterval(interval);
        this.$router.push("/" + this.company + "/ekran_final");
      }, 11000);

      var defaults = {
        startVelocity: this.randomInRange(15, 50),
        spread: 360,
        ticks: 100,
        zIndex: 3,
      };

      let interval = setInterval(function () {
        var particleCount = randomInRange(100, 150);

        function randomInRange(min, max) {
          return Math.random() * (max - min) + min;
        }

        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 300);

      confetti({
        particleCount: 200,
        scalar: 0.5,
        angle: 60,
        spread: 80,
        origin: { x: 0 },
        startVelocity: 80,
      });
      confetti({
        particleCount: 200,
        scalar: 0.5,
        angle: 120,
        spread: 80,
        origin: { x: 1 },
        startVelocity: 80,
      });
    },
  },

  mounted() {
    this.loadCss();
    this.getCompany();
    this.createAllNumbers();
  },

  watch: {
    last_number: function (val, oldVal) {
      if (val != oldVal) {
        this.animateCSS(".lastN", "bounceIn");
      }
    },

    numbersOpen: function (val, oldVal) {
      console.log("numbersOpennumbersOpen");
      if (val != oldVal) {
        this.numbersOpen.forEach((element) => {
          this.numbersAll[element - 1].dolu = 1;
        });
      }
    },
    gameStatus: function (val) {
      if (val == 2) {
        this.$router.push("/" + this.$route.meta.company + "/ekran_final");
      }
    },
  },
};
</script>
