<template>
    <div class=" m-4">

        <div class="row">
            <div class="col-12 p-1">

                <div>
                    <b-button size="sm" @click="toplamAktifSay()" variant="primary">Aktif Oyuncu</b-button> {{toplamAktif}}
                </div>

                <div class="card mt-2 mb-2">
                    <div class="card-body p-1">
                        <div  class="number_box_selected round_box font_medium m-1" v-for="item in this.selectedNumbers.slice().reverse()" :key="item">
                            {{item}}
                        </div>
                    </div>
                </div>


            </div>

            <div class="col-4 p-1">

                <div class="card">
                    <div class="card-body p-1">
                        <div class="font_semibold f_16 text-center mb-2">1. Çinko - {{cinko1Users.length}}</div>
                        <hr class="mt-0">
                        <ol id="example-1" class="pl-4">
                            <li v-for="item in cinko1Users.slice(0, 4)" :key="item.userId">
                                {{ item.userName }} 
                            </li>
                        </ol>
                    </div>
                </div>

            </div>
            <div class="col-4 p-1">

                <div class="card">
                    <div class="card-body p-1">
                        <div class="font_semibold f_16 text-center mb-2">2. Çinko - {{cinko2Users.length}}</div>
                        <hr class="mt-0">
                        <ol id="example-1" class="pl-4">
                            <li v-for="item in cinko2Users.slice(0, 4)" :key="item.userId">
                                {{ item.userName }} 
                            </li>
                        </ol>
                    </div>
                </div>

            </div>
            <div class="col-4 p-1">

                <div class="card">
                    <div class="card-body p-1">
                        <div class="font_semibold f_16 text-center mb-2">Tombala - {{tombalaUsers.length}}</div>
                        <hr class="mt-0">
                        <ol id="example-1" class="pl-4">
                            <li v-for="item in tombalaUsers.slice(0, 1)" :key="item.userId">
                                {{ item.userName }} 
                            </li>
                        </ol>
                    </div>
                </div>

            </div>



        </div>

    </div>
</template>

<style lang="scss">
@import '~@/styles/master.css';
</style>


<script>


import { initializeApp } from "firebase/app";
import { getDatabase,ref,child,onValue,get } from "firebase/database";

const config = {
    apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
    authDomain: "tombalabiz.firebaseapp.com",
    databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
    projectId: "tombalabiz",
    storageBucket: "tombalabiz.appspot.com",
    messagingSenderId: "225198155208",
    appId: "1:225198155208:web:e697d08f91c323dec5ccfd"
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);

export default {

    data(){
        return {
            company: "",
            secret: "",
            numbers: [],
            selectedNumbers:[],
            cinko1Users : [],
            cinko2Users : [],
            tombalaUsers : [],

            cinko1Users_temp : [],
            cinko2Users_temp : [],
            tombalaUsers_temp : [],

            awardLastNumberUsers : [],
            awardLastNumberStatus : 0,
            awardLastNumberVar : 0,
            awardLastNumber : 0,

            awardGuessNumberUsers : [],
            awardGuessNumberStatus : 0,
            awardGuessNumberVar : 0,
            awardGuessNumber : 0,

            toplamAktif: 0
        }
    },

    methods:{

        toplamAktifSay(){

            let nRefusers = this.company+"/users";
            get(child(dbRef, nRefusers)).then((snapshot) => {
            if (snapshot.exists()) {

                this.toplamAktif = 0;
                snapshot.forEach(e => {

                        if(e.val().active == "1"){
                            this.toplamAktif ++;
                        }
                    
                });



            } 
            }).catch((error) => {
                console.log(error);
            });

        },
        
        orderedItems(items) {
            items.sort((a, b) => {
                return a.times - b.times;
            });  
            return items;
        },

        getCompany(){
            this.company = this.$route.meta.company;
        },
        
        showToast(gelen){

            this.$bvToast.toast(gelen, {
                title: "Hata!",
                autoHideDelay: 3000,
                appendToast: true,
                variant: 'danger',
                solid: true,
            })
            
        },



        findUserNameCinko1(){

            this.cinko1Users = this.orderedItems(this.cinko1Users);

            this.cinko1Users.forEach(e => {
                let userId = e.userId;

                if(e.userName == ""){

                    let nRef = this.company+"/users/"+userId+"/name";
                    get(child(dbRef, nRef)).then((snapshot) => {
                        if (snapshot.exists()) {
                            const data = snapshot.val();
                            e.userName = data                       
                        } 
                    }).catch((error) => {
                        console.error(error);
                    });
                
                }

            });
            
        },
        findUserNameCinko2(){

            this.cinko2Users = this.orderedItems(this.cinko2Users);

            this.cinko2Users.forEach(e => {
                let userId = e.userId;
                if(e.userName == ""){
                    let nRef = this.company+"/users/"+userId+"/name";
                    get(child(dbRef, nRef)).then((snapshot) => {
                        if (snapshot.exists()) {
                            const data = snapshot.val();
                            e.userName = data                       
                        } 
                    }).catch((error) => {
                        console.error(error);
                    });
                }

            });

            
        },
        findUserNameTombala(){
            this.tombalaUsers = this.orderedItems(this.tombalaUsers);
            this.tombalaUsers.forEach(e => {
                let userId = e.userId;
                if(e.userName == ""){
                    let nRef = this.company+"/users/"+userId+"/name";
                    get(child(dbRef, nRef)).then((snapshot) => {
                        if (snapshot.exists()) {
                            const data = snapshot.val();
                            e.userName = data                       
                        } 
                    }).catch((error) => {
                        console.error(error);
                    });
                }

            });
        },


        checkIfCinko1Contains(userId){
            let res = false;
            this.cinko1Users.forEach(element => {
                if(element.userId == userId){
                    res = true
                }
            });
            return res;
        },

        checkIfCinko2Contains(userId){
            let res = false;
            this.cinko2Users.forEach(element => {
                if(element.userId == userId){
                    res = true
                }
            });
            return res;
        },

        checkIfTombalaContains(userId){
            let res = false;
            this.tombalaUsers.forEach(element => {
                if(element.userId == userId){
                    res = true
                }
            });
            return res;
        },
        
        checkIfLastNumberContains(userId){
            let res = false;
            this.awardLastNumberUsers.forEach(element => {
                if(element.userId == userId){
                    res = true
                }
            });
            return res;
        },

        checkIfGuessNumberContains(userId){
            let res = false;
            this.awardGuessNumberUsers.forEach(element => {
                if(element.userId == userId){
                    res = true
                }
            });
            return res;
        },

        create_Cinko1(){
            this.cinko1Users = [];
            this.cinko1Users_temp.forEach(e => {

                if(!this.checkIfCinko2Contains(e.userId) && !this.checkIfTombalaContains(e.userId)){
                    this.cinko1Users.push(e);
                }
                
                
            });
        },
        create_Cinko2(){
            this.cinko2Users = [];
            this.cinko2Users_temp.forEach(e => {

                if(!this.checkIfTombalaContains(e.userId) ){
                    this.cinko2Users.push(e);
                }

            });
        },
        create_Tombala(){

            this.tombalaUsers = [];
            
            this.tombalaUsers_temp.forEach(e => {
                this.tombalaUsers.push(e);
            });
            

        },


    },

    mounted () {
        this.getCompany();
        

        
        const refOpens = ref(db, this.company+"/opens");
        onValue(refOpens, (snapshot) => {
            const data = snapshot.val();
            if(data != null){
                this.selectedNumbers = data;
            }
            
        });

        for (let index = 1; index < 100; index++) {
            let temp = {
                number:index,
                selected:0
            }
            this.numbers.push(temp);
        }

        const refTombala = ref(db, this.company+"/tombalaUsers");
        onValue(refTombala, (snapshot) => {
            
            let _tmp = [];

            snapshot.forEach(e => {

                let temp = {
                    userId:e.key,
                    userName: "",
                    times:e.val()
                };

                _tmp.push(temp)

            });
            
            this.tombalaUsers_temp = _tmp;
            this.create_Tombala();
            this.findUserNameTombala();

        });

        const refCinko2 = ref(db, this.company+"/cinko2Users");
        onValue(refCinko2, (snapshot) => {

            let _tmp = [];
            
            snapshot.forEach(e => {

                let temp = {
                    userId:e.key,
                    userName: "",
                    times:e.val()
                };
                _tmp.push(temp)
                
            
            });

            this.cinko2Users_temp = _tmp;
            this.create_Cinko2();
            this.findUserNameCinko2();

        });

        const refCinko1 = ref(db, this.company+"/cinko1Users");
        onValue(refCinko1, (snapshot) => {

            let _tmp = [];
            snapshot.forEach(e => {

                
                let temp = {
                    userId:e.key,
                    userName: "",
                    times:e.val()
                };
                _tmp.push(temp)
                

            });

            this.cinko1Users_temp = _tmp;
            this.create_Cinko1();
            this.findUserNameCinko1();

        });





    },

    watch: {

        cinko1Users_temp : function(val, oldVal){
            if (val != oldVal) {
                this.create_Tombala();
                this.create_Cinko2();
                this.create_Cinko1();
            }
        },

        cinko2Users_temp : function(val, oldVal){
            if (val != oldVal) {
                this.create_Tombala();
                this.create_Cinko2();
                this.create_Cinko1();
            }
        },

        tombalaUsers_temp : function(val, oldVal){
            if (val != oldVal) {
                this.create_Tombala();
                this.create_Cinko2();
                this.create_Cinko1();
            }
        },


        
        cinko1Users : function(val, oldVal){
            if (val != oldVal) {
                this.findUserNameCinko1();
                this.findUserNameCinko2();
                this.findUserNameTombala();
            }
        },
        cinko2Users : function(val, oldVal){
            if (val != oldVal) {
                this.findUserNameCinko1();
                this.findUserNameCinko2();
                this.findUserNameTombala();
            }
        },
        tombalaUsers : function(val, oldVal){
            if (val != oldVal) {
                this.findUserNameCinko1();
                this.findUserNameCinko2();
                this.findUserNameTombala();
            }
        },

       


        

    },


    

}
</script>