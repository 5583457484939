<template>
  <div class="pb_80">
    <div class="container dw640">
      <b-overlay
        :show="showOverlay"
        variant="dark"
        opacity="0.90"
        z-index="1"
        no-wrap
      ></b-overlay>
      <div v-if="showOverlay" class="overlayText overlayIc font_semibold">
        <div class="tebrikler_font_size">{{ text_tebrikler }}</div>
        <div class="">{{ tebriklerYazi() }}</div>
      </div>

      <div class="mt-4">{{ text_merhaba }} {{ name }},</div>

      <div class="f_22 font_semibold text-center mt-4">
        {{ text_son_cekilen }}
      </div>
      <div
        ref="last"
        class="lastN lastNumber_bos mt-2 mb-3 text-center font_semibold son_cekilen_color"
      >
        {{ lastNumber }}
      </div>

      <div
        class="row border_card round10 text-center cell_font_size font_semibold mt-2 cardo"
      >
        <div class="col">
          <div class="row color_cart round10_sol_ust round10_sag_ust">
            <div class="col cell_height bgWhite round10_sol_ust pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(0), 0)"
                :class="my_class_bul(showCardNumberOpen(0), showCardNumber(0))"
              >
                {{ showCardNumber(0) }}
              </div>
            </div>
            <div class="col pl-0 bg_card pr-0"></div>
            <div class="col bgWhite pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(3), 3)"
                :class="my_class_bul(showCardNumberOpen(3), showCardNumber(3))"
              >
                {{ showCardNumber(3) }}
              </div>
            </div>
            <div class="col pl-0 bg_card pr-0"></div>
            <div class="col bgWhite pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(6), 6)"
                :class="my_class_bul(showCardNumberOpen(6), showCardNumber(6))"
              >
                {{ showCardNumber(6) }}
              </div>
            </div>
            <div class="col pl-0 bg_card pr-0 pl-0 pr-0"></div>
            <div class="col bgWhite pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(9), 9)"
                :class="my_class_bul(showCardNumberOpen(9), showCardNumber(9))"
              >
                {{ showCardNumber(9) }}
              </div>
            </div>
            <div class="col bgWhite border_left_card pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(11), 11)"
                :class="
                  my_class_bul(showCardNumberOpen(11), showCardNumber(11))
                "
              >
                {{ showCardNumber(11) }}
              </div>
            </div>
            <div class="col pl-0 bg_card round10_sag_ust pr-0"></div>
          </div>

          <div class="row color_cart">
            <div class="col bg_card cell_height pl-0 pr-0"></div>
            <div class="col bgWhite pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(2), 2)"
                :class="my_class_bul(showCardNumberOpen(2), showCardNumber(2))"
              >
                {{ showCardNumber(2) }}
              </div>
            </div>
            <div class="col pl-0 pr-0 bg_card"></div>
            <div class="col bgWhite pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(5), 5)"
                :class="my_class_bul(showCardNumberOpen(5), showCardNumber(5))"
              >
                {{ showCardNumber(5) }}
              </div>
            </div>
            <div class="col pl-0 pr-0 bg_card"></div>
            <div class="col bgWhite pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(8), 8)"
                :class="my_class_bul(showCardNumberOpen(8), showCardNumber(8))"
              >
                {{ showCardNumber(8) }}
              </div>
            </div>
            <div class="col pl-0 pr-0 bg_card"></div>
            <div class="col bgWhite border_top_card pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(12), 12)"
                :class="
                  my_class_bul(showCardNumberOpen(12), showCardNumber(12))
                "
              >
                {{ showCardNumber(12) }}
              </div>
            </div>
            <div class="col bgWhite border_left_card pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(13), 13)"
                :class="
                  my_class_bul(showCardNumberOpen(13), showCardNumber(13))
                "
              >
                {{ showCardNumber(13) }}
              </div>
            </div>
          </div>
          <div class="row color_cart round10_sag_alt round10_sol_alt">
            <div class="col cell_height bgWhite round10_sol_alt pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(1), 1)"
                :class="my_class_bul(showCardNumberOpen(1), showCardNumber(1))"
              >
                {{ showCardNumber(1) }}
              </div>
            </div>
            <div class="col bg_card pl-0 pr-0"></div>
            <div class="col bgWhite pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(4), 4)"
                :class="my_class_bul(showCardNumberOpen(4), showCardNumber(4))"
              >
                {{ showCardNumber(4) }}
              </div>
            </div>
            <div class="col bg_card pl-0 pr-0"></div>
            <div class="col bgWhite pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(7), 7)"
                :class="my_class_bul(showCardNumberOpen(7), showCardNumber(7))"
              >
                {{ showCardNumber(7) }}
              </div>
            </div>
            <div class="col bg_card pl-0 pr-0"></div>
            <div class="col bgWhite pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(10), 10)"
                :class="
                  my_class_bul(showCardNumberOpen(10), showCardNumber(10))
                "
              >
                {{ showCardNumber(10) }}
              </div>
            </div>
            <div class="col bg_card pl-0 pr-0"></div>
            <div class="col bgWhite border_top_card round10_sag_alt pl-0 pr-0">
              <div
                @click="cellClick(showCardNumber(14), 14)"
                :class="
                  my_class_bul(showCardNumberOpen(14), showCardNumber(14))
                "
              >
                {{ showCardNumber(14) }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="f_20 font_semibold text-center mt-5 mb-3">
        {{ text_cikmis_sayilar }}
      </div>

      <div
        class="mb-5 d-flex align-items-start flex-wrap justify-content-center"
      >
        <div
          v-for="item in numbersAll"
          :key="item.number"
          :class="open_class_bul(item.dolu)"
        >
          {{ item.number }}
        </div>
      </div>

      <div
        class="modal-backdrop fade show"
        id="backdrop"
        style="display: none"
      ></div>
    </div>
  </div>
</template>

<style></style>

<script>
import turkish from "@/assets/tr-flag.png";
import english from "@/assets/en-flag.png";

import { initializeApp } from "firebase/app";
import {
  getDatabase,
  onValue,
  ref,
  get,
  child,
  set,
  serverTimestamp,
} from "firebase/database";
import confetti from "canvas-confetti";
//const confetti = require('canvas-confetti');

const config = {
  apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
  authDomain: "tombalabiz.firebaseapp.com",
  databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
  projectId: "tombalabiz",
  storageBucket: "tombalabiz.appspot.com",
  messagingSenderId: "225198155208",
  appId: "1:225198155208:web:e697d08f91c323dec5ccfd",
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);

const myCss = (mss) => import("@/styles/" + mss + ".css");

export default {
  data() {
    return {
      language: "tr",
      flag: turkish,
      company: "",
      email: "",
      uid: "",
      name: "",
      numbersAll: [],
      lastNumber: "",
      lastCinko1: "0",
      lastCinko2: "0",
      lastTombala: "0",

      numbersOpen: [],
      numbersMy: [],
      toplamCinko: 0,
      isTombala: 0,

      firma_logo: "",

      award_last_number: 0,
      award_last_number_status: this.$store.getters.getAwardLastNumberStatus,

      award_guess_number: 0,
      award_guess_number_status: this.$store.getters.getAwardGuessNumberStatus,

      text_son_cekilen: "",
      text_cikmis_sayilar: "",
      text_merhaba: "",
      text_tebrikler: "",
      text_tebrik_cinko: "",
      text_tebrik_tombala: "",
      text_alert_cinko: "",
      text_alert_tombala: "",
      text_cinko: "",
      text_tombala: "",
      text_award_last_number: "",
      text_award_last_number_title: "",
      text_award_guess_number: "",
      text_award_guess_number_title: "",

      text_hediye_tamam: "",

      showOverlay: false,
      gameStatus: 1,
    };
  },

  async created() {
    let res = await import("@/translate/" + this.$route.meta.company + ".json");
    let res2 = JSON.stringify(res);
    let translate = JSON.parse(res2);

    let translate_icerik = translate.tr;

    this.text_son_cekilen = translate_icerik.son_cekilen;
    this.text_cikmis_sayilar = translate_icerik.cikmis_sayilar;
    this.text_merhaba = translate_icerik.merhaba;
    this.text_tebrikler = translate_icerik.tebrikler;
    this.text_tebrik_cinko = translate_icerik.tebrik_cinko;
    this.text_tebrik_tombala = translate_icerik.tebrik_tombala;

    this.text_alert_cinko = translate_icerik.alert_cinko;
    this.text_alert_tombala = translate_icerik.alert_tombala;
    this.text_cinko = translate_icerik.cinko;
    this.text_tombala = translate_icerik.tombala;

    this.text_award_last_number = translate_icerik.award_last_number;
    this.text_award_last_number_title =
      translate_icerik.award_last_number_title;

    this.text_award_guess_number = translate_icerik.award_guess_number;
    this.text_award_guess_number_title =
      translate_icerik.award_guess_number_title;

    this.text_hediye_tamam = translate_icerik.hediye_tamam;
    this.firma_logo = translate_icerik.firma_logo;
  },

  methods: {
    kapaliListe() {
      let don = [];
      for (let index = 1; index < 100; index++) {
        if (this.numbersOpen == null) {
          don.push(index);
        } else {
          if (this.numbersOpen.includes(index)) {
            console.log("");
          } else {
            don.push(index);
          }
        }
      }
      return don;
    },

    cellClick(number, ind) {
      if (this.numbersOpen.includes(number)) {
        this.numbersMy.forEach((element) => {
          if (element.number == number && element.opened == 0) {
            element.opened = 1;
            let uni_dot = ".ce" + number;
            setTimeout(() => this.animateCSS(uni_dot, "flip"), 50);
            set(
              ref(
                db,
                this.company +
                  "/users/" +
                  this.uid +
                  "/allNumbers/" +
                  ind +
                  "/opened"
              ),
              1
            );

            this.checkCinkoTombala();

            if (
              this.award_last_number_status == 1 &&
              this.award_last_number != 0 &&
              number == this.award_last_number
            ) {
              set(
                ref(db, this.company + "/awardLastNumberUsers/" + this.uid),
                serverTimestamp()
              );
            }
          }
        });
      }
    },
    checkCinkoTombala() {
      if (this.toplamCinko == 0) {
        //çinko yok

        let cinkoVar = 0;
        let satir_1_toplam = 5;
        let satir_2_toplam = 5;
        let satir_3_toplam = 5;

        let satir_1_open = 0;
        let satir_2_open = 0;
        let satir_3_open = 0;

        this.numbersMy.forEach((e) => {
          if (e.cinko == 1 && e.opened == 1) {
            satir_1_open++;
          }
          if (e.cinko == 2 && e.opened == 1) {
            satir_2_open++;
          }
          if (e.cinko == 3 && e.opened == 1) {
            satir_3_open++;
          }
        });

        if (satir_1_toplam == satir_1_open) {
          cinkoVar = 1;
        }
        if (satir_2_toplam == satir_2_open) {
          cinkoVar = 1;
        }
        if (satir_3_toplam == satir_3_open) {
          cinkoVar = 1;
        }

        if (cinkoVar == 1) {
          this.toplamCinko = 1;
          set(ref(db, this.company + "/users/" + this.uid + "/cinko/"), 1);
          set(
            ref(db, this.company + "/cinko1Users/" + this.uid),
            serverTimestamp()
          );
          set(ref(db, this.company + "/lastCinko1"), this.uid);
          this.cinkoDone();
        }
      } else if (this.toplamCinko == 1) {
        //tek çinko var
        let cinkoVar = 0;
        let satir_1_toplam = 5;
        let satir_2_toplam = 5;
        let satir_3_toplam = 5;

        let satir_1_open = 0;
        let satir_2_open = 0;
        let satir_3_open = 0;

        this.numbersMy.forEach((e) => {
          if (e.cinko == 1 && e.opened == 1) {
            satir_1_open++;
          }
          if (e.cinko == 2 && e.opened == 1) {
            satir_2_open++;
          }
          if (e.cinko == 3 && e.opened == 1) {
            satir_3_open++;
          }
        });

        if (satir_1_toplam == satir_1_open) {
          cinkoVar += 1;
        }
        if (satir_2_toplam == satir_2_open) {
          cinkoVar += 1;
        }
        if (satir_3_toplam == satir_3_open) {
          cinkoVar += 1;
        }

        if (cinkoVar == 2) {
          this.toplamCinko = 2;
          set(ref(db, this.company + "/users/" + this.uid + "/cinko"), 2);
          set(
            ref(db, this.company + "/cinko2Users/" + this.uid),
            serverTimestamp()
          );
          set(ref(db, this.company + "/lastCinko2"), this.uid);
          this.cinkoDone();
        }
      } else if (this.toplamCinko == 2) {
        //2 çinko var tombala aranıyor

        let cinkoVar = 0;
        let satir_1_toplam = 5;
        let satir_2_toplam = 5;
        let satir_3_toplam = 5;

        let satir_1_open = 0;
        let satir_2_open = 0;
        let satir_3_open = 0;

        this.numbersMy.forEach((e) => {
          if (e.cinko == 1 && e.opened == 1) {
            satir_1_open++;
          }
          if (e.cinko == 2 && e.opened == 1) {
            satir_2_open++;
          }
          if (e.cinko == 3 && e.opened == 1) {
            satir_3_open++;
          }
        });

        if (satir_1_toplam == satir_1_open) {
          cinkoVar += 1;
        }
        if (satir_2_toplam == satir_2_open) {
          cinkoVar += 1;
        }
        if (satir_3_toplam == satir_3_open) {
          cinkoVar += 1;
        }

        if (cinkoVar == 3) {
          this.toplamCinko = 3;
          this.isTombala = 1;
          set(ref(db, this.company + "/users/" + this.uid + "/cinko"), 3);
          set(ref(db, this.company + "/users/" + this.uid + "/tombala"), 1);
          set(
            ref(db, this.company + "/tombalaUsers/" + this.uid),
            serverTimestamp()
          );
          set(ref(db, this.company + "/lastTombala"), this.uid);
          this.tombalaDone();
        }
      }
    },

    findUserNameCinko1(userId) {
      let nRef = this.company + "/users/" + userId + "/name";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            this.showToast(
              data + this.text_alert_cinko,
              "1. " + this.text_cinko
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    findUserNameCinko2(userId) {
      let nRef = this.company + "/users/" + userId + "/name";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            this.showToast(
              data + this.text_alert_cinko,
              "2. " + this.text_cinko
            );
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    findUserNameTombala(userId) {
      let nRef = this.company + "/users/" + userId + "/name";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            this.showToast(data + this.text_alert_tombala, this.text_tombala);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },

    cinkoDone() {
      this.showOverlay = true;

      setTimeout(() => this.animateCSS(".overlayIc", "heartBeat"), 50);
      setTimeout(() => this.animateCSS(".overlayIc", "bounceOut"), 4000);
      setTimeout(() => (this.showOverlay = false), 4500);

      this.fire(0.25, {
        spread: 26,
        startVelocity: 55,
        zIndex: 3,
      });

      this.fire(0.2, {
        spread: 60,
        zIndex: 3,
      });

      this.fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        zIndex: 3,
      });

      this.fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.5,
        zIndex: 3,
      });

      this.fire(0.1, {
        spread: 150,
        startVelocity: 45,
        zIndex: 3,
      });
    },
    tombalaDone() {
      this.showOverlay = true;

      setTimeout(() => this.animateCSS(".overlayIc", "heartBeat"), 50);
      setTimeout(() => this.animateCSS(".overlayIc", "bounceOut"), 10000);
      setTimeout(() => (this.showOverlay = false), 10500);
      setTimeout(() => clearInterval(interval), 11000);

      var defaults = {
        startVelocity: this.randomInRange(15, 40),
        spread: 360,
        ticks: 60,
        zIndex: 3,
      };

      let interval = setInterval(function () {
        var particleCount = randomInRange(200, 300);

        function randomInRange(min, max) {
          return Math.random() * (max - min) + min;
        }

        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
          })
        );
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
          })
        );
      }, 300);

      confetti({
        particleCount: 400,
        scalar: 0.5,
        angle: 60,
        spread: 80,
        origin: { x: 0 },
        startVelocity: 80,
      });
      confetti({
        particleCount: 400,
        scalar: 0.5,
        angle: 120,
        spread: 80,
        origin: { x: 1 },
        startVelocity: 80,
      });
    },
    randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    },
    tebriklerYazi() {
      let yazi = "";
      if (this.isTombala == 1) {
        yazi = this.text_tebrik_tombala;
      } else {
        let yy = "1. ";
        if (this.toplamCinko == 2) {
          yy = "2. ";
        }
        yazi = yy + this.text_tebrik_cinko;
      }
      return yazi;
    },

    testIt() {
      this.openAwardModal4GuessNumber();
    },
    open_class_bul(gelen) {
      let ret = "numbers_list_bos font_semibold ";
      if (gelen == 1) {
        ret = "numbers_list_dolu font_semibold ";
      }
      return ret;
    },
    my_class_bul(gelen, number) {
      let uni = "ce" + number;
      let ret = "cell_padding pointer " + uni;
      if (gelen == 1) {
        //let uni_dot = ".ce"+number;
        ret = "opened_number " + uni;

        //setTimeout(() => this.animateCSS(uni_dot, 'flip'), 200);
      }
      return ret;
    },
    showCardNumber(gelen) {
      let ret = 0;
      if (typeof this.numbersMy[gelen] === "undefined") {
        ret = "";
      } else {
        ret = this.numbersMy[gelen].number;
      }
      return ret;
    },
    showCardNumberOpen(gelen) {
      let ret = 0;
      if (typeof this.numbersMy[gelen] === "undefined") {
        ret = 0;
      } else {
        ret = this.numbersMy[gelen].opened;
      }
      return ret;
    },
    getCompany() {
      this.company = this.$route.meta.company;
      myCss(this.$route.meta.company);
    },
    showToast(gelen, title) {
      this.$bvToast.toast(gelen, {
        title: title,
        autoHideDelay: 1700,
        appendToast: true,
        toaster: "b-toaster-bottom-right",
        variant: "secondary",
        solid: true,
      });
    },
    changeLang(gelen) {
      if (gelen == "tr") {
        this.flag = turkish;
      } else {
        this.flag = english;
      }
      this.language = gelen;
      this.$store.commit("setLanguage", gelen);
      window.location.reload();
    },
    createAllNumbers() {
      for (let i = 1; i < 100; i++) {
        let dolu = 0;
        if (this.numbersOpen != null) {
          if (this.numbersOpen.includes(i)) {
            dolu = 1;
          }
        }

        let temp = {
          number: i,
          dolu: dolu,
        };

        this.numbersAll.push(temp);
      }
    },
    createNumbers() {
      var arr = [];
      while (arr.length < 15) {
        var r = Math.floor(Math.random() * 100) + 1;
        if (arr.indexOf(r) === -1) arr.push(r);
      }

      arr.sort(function (a, b) {
        return a - b;
      });

      this.numbersAll = arr;
    },
    degistir() {
      this.numbersAll = [];
      this.createNumbers();
      this.animateCSS(".cardo", "flipInX");
    },
    animateCSS(element, animation, prefix = "animate__") {
      new Promise((resolve) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);
        node.classList.add(`${prefix}animated`, animationName);
        function handleAnimationEnd(event) {
          event.stopPropagation();
          node.classList.remove(`${prefix}animated`, animationName);
          resolve("Animation ended");
        }
        node.addEventListener("animationend", handleAnimationEnd, {
          once: true,
        });
      });
    },
    connectDb() {
      console.log("connectDb");

      const refLastOpen = ref(db, this.company + "/lastOpen");
      onValue(refLastOpen, (snapshot) => {
        const data = snapshot.val();

        if (data != "0") {
          this.lastNumber = data;

          this.$refs["last"].classList.remove("lastNumber_bos");
          this.$refs["last"].classList.add("lastNumber_dolu");
        } else {
          this.$refs["last"].classList.remove("lastNumber_dolu");
          this.$refs["last"].classList.add("lastNumber_bos");

          this.lastNumber = "";
        }
      });

      const refOpens = ref(db, this.company + "/opens");
      onValue(refOpens, (snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          this.numbersOpen = data;
        }
      });

      const refLastCinko1 = ref(db, this.company + "/lastCinko1");
      onValue(refLastCinko1, (snapshot) => {
        const data = snapshot.val();

        this.lastCinko1 = data;
      });

      const refLastCinko2 = ref(db, this.company + "/lastCinko2");
      onValue(refLastCinko2, (snapshot) => {
        const data = snapshot.val();

        this.lastCinko2 = data;
      });

      const refLastTombala = ref(db, this.company + "/lastTombala");
      onValue(refLastTombala, (snapshot) => {
        const data = snapshot.val();

        this.lastTombala = data;
      });

      const refStatus = ref(db, this.company + "/status");
      onValue(refStatus, (snapshot) => {
        const data = snapshot.val();
        this.gameStatus = data;
      });

      let nRef = this.company + "/users/" + this.uid + "/allNumbers";

      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();
            this.numbersMy = data;
          } else {
            this.$router.push("/" + this.company);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      let nRefCinko1 = this.company + "/users/" + this.uid + "/cinko";
      get(child(dbRef, nRefCinko1)).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          this.toplamCinko = data;
        }
      });

      let nRefTombala = this.company + "/users/" + this.uid + "/tombala";
      get(child(dbRef, nRefTombala)).then((snapshot) => {
        if (snapshot.exists()) {
          const data = snapshot.val();
          this.isTombala = data;
        }
      });
    },
    fire(particleRatio, opts) {
      var count = 500;
      var defaults = {
        origin: { y: 0.6 },
      };

      confetti(
        Object.assign({}, defaults, opts, {
          particleCount: Math.floor(count * particleRatio),
        })
      );
    },

    checkUser() {
      let nRef = this.company + "/users/" + this.uid + "/allNumbers";

      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            console.log("user var");
          } else {
            console.log("user yok");
            this.$router.push("/" + this.company);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },

  watch: {
    lastNumber: function (val, oldVal) {
      if (val != oldVal) {
        this.animateCSS(".lastN", "bounceIn");
      }
      this.checkUser();
    },
    numbersOpen: function (val, oldVal) {
      if (val != oldVal) {
        this.numbersOpen.forEach((element) => {
          this.numbersAll[element - 1].dolu = 1;
        });
      }
    },
    lastCinko1: function (val, oldVal) {
      if (val != oldVal) {
        //console.log(val);
        //console.log(this.$store.getters.getlastCinko1Userid);
        if (val != this.$store.getters.getlastCinko1Userid) {
          this.findUserNameCinko1(val);
          this.$store.commit("setlastCinko1Userid", val);
        }
      }
    },
    lastCinko2: function (val, oldVal) {
      if (val != oldVal) {
        if (val != this.$store.getters.getlastCinko2Userid) {
          this.findUserNameCinko2(val);
          this.$store.commit("setlastCinko2Userid", val);
        }
      }
    },
    lastTombala: function (val, oldVal) {
      if (val != oldVal) {
        if (val != this.$store.getters.getlastTombalaUserid) {
          this.findUserNameTombala(val);
          this.$store.commit("setlastTombalaUserid", val);
        }
      }
    },
    gameStatus: function (val) {
      if (val == 2) {
        this.$router.push("/" + this.company + "/final");
      }
    },

    award_last_number_status: function (val, oldVal) {
      if (val == 1 && oldVal == 0) {
        this.openAwardModal4LastNumber();
      }
    },

    award_guess_number_status: function (val, oldVal) {
      if (val == 1 && oldVal == 0) {
        this.openAwardModal4GuessNumber();
      }
    },
  },

  mounted() {
    this.language = this.$store.getters.getLanguage;
    this.email = this.$store.getters.getEmail;
    this.uid = this.$store.getters.getUid;
    this.name = this.$store.getters.getName;

    this.getCompany();

    //this.createNumbers();
    this.connectDb();
    this.createAllNumbers();

    if (this.language == "tr") {
      this.flag = turkish;
    } else {
      this.flag = english;
    }

    //console.log(this.numbersMy);
  },
};
</script>
