import Vuex from 'vuex'
import Vue from 'vue'
import createPersistedState from 'vuex-persistedstate'

//import Auth from './modules/auth'

Vue.use(Vuex);

const getters = {
    getLanguage: state => {
        return state.language
    },
    getUid: state => {
        return state.uid
    },
    getIsAdmin: state => {
        return state.isAdmin
    },
    getName: state => {
        return state.name
    },
    getEmail: state => {
        return state.email
    },
    getAwardLastNumberStatus: state => {
        return state.awardLastNumberStatus
    },
    getAwardGuessNumberStatus: state => {
        return state.awardGuessNumberStatus
    },

    getlastCinko1Userid: state => {
        return state.lastCinko1Userid
    },
    getlastCinko2Userid: state => {
        return state.lastCinko2Userid
    },
    getlastTombalaUserid: state => {
        return state.lastTombalaUserid
    }
    
    
};
const actions = {};
const mutations = {
    setLanguage (state, data) {
        state.language = data;
    },
    setUid (state, data) {
        state.uid = data;
    },
    setIsAdmin (state, data) {
        state.isAdmin = data;
    },
    setName (state, data) {
        state.name = data;
    },
    setEmail (state, data) {
        state.email = data;
    },
    setAwardLastNumberStatus (state, data) {
        state.awardLastNumberStatus = data;
    },
    setAwardGuessNumberStatus (state, data) {
        state.awardGuessNumberStatus = data;
    },

    setlastCinko1Userid (state, data) {
        state.lastCinko1Userid = data;
    },
    setlastCinko2Userid (state, data) {
        state.lastCinko2Userid = data;
    },
    setlastTombalaUserid (state, data) {
        state.lastTombalaUserid = data;
    },



};

export const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        language : "tr",
        uid : "",
        name : "",
        email : "",
        isAdmin : "0",
        awardLastNumberStatus : 0,
        awardGuessNumberStatus : 0,
        lastCinko1Userid: "",
        lastCinko2Userid: "",
        lastTombalaUserid: ""

    },
    actions,
    getters,
    mutations,
});

export default store;

