<template>
  <div>
    <div class="container dw640 pb_80">
      <div class="f_20 font_semibold text-center mt-5">{{ text_card_sec }}</div>

      <div
        class="row border_card round10 text-center cell_font_size font_semibold mt-5 cardo"
      >
        <div class="col">
          <div class="row color_cart round10_sol_ust round10_sag_ust">
            <div
              class="col cell_height bgWhite round10_sol_ust cell_padding pl-0 pr-0"
            >
              <span class="xc0">{{ numbersAll[0] }}</span>
            </div>
            <div class="col pl-0 bg_card pr-0"></div>
            <div class="col bgWhite cell_padding pl-0 pr-0">
              <span class="xc3">{{ numbersAll[3] }}</span>
            </div>
            <div class="col pl-0 bg_card pr-0"></div>
            <div class="col bgWhite cell_padding pl-0 pr-0">
              <span class="xc6">{{ numbersAll[6] }}</span>
            </div>
            <div class="col pl-0 bg_card pr-0 pl-0 pr-0"></div>
            <div class="col bgWhite cell_padding pl-0 pr-0">
              <span class="xc9">{{ numbersAll[9] }}</span>
            </div>
            <div class="col bgWhite border_left_card cell_padding pl-0 pr-0">
              <span class="xc11">{{ numbersAll[11] }}</span>
            </div>
            <div class="col pl-0 bg_card round10_sag_ust pr-0"></div>
          </div>
          <div class="row color_cart">
            <div class="col bg_card cell_height cell_padding pl-0 pr-0"></div>
            <div class="col bgWhite cell_padding pl-0 pr-0">
              <span class="xc2">{{ numbersAll[2] }}</span>
            </div>
            <div class="col pl-0 pr-0 bg_card"></div>
            <div class="col bgWhite cell_padding pl-0 pr-0">
              <span class="xc5">{{ numbersAll[5] }}</span>
            </div>
            <div class="col pl-0 pr-0 bg_card"></div>
            <div class="col bgWhite cell_padding pl-0 pr-0">
              <span class="xc8">{{ numbersAll[8] }}</span>
            </div>
            <div class="col pl-0 pr-0 bg_card"></div>
            <div class="col bgWhite border_top_card cell_padding pl-0 pr-0">
              <span class="xc12">{{ numbersAll[12] }}</span>
            </div>
            <div class="col bgWhite border_left_card cell_padding pl-0 pr-0">
              <span class="xc13">{{ numbersAll[13] }}</span>
            </div>
          </div>
          <div class="row color_cart round10_sag_alt round10_sol_alt">
            <div
              class="col cell_height bgWhite round10_sol_alt cell_padding pl-0 pr-0"
            >
              <span class="xc1">{{ numbersAll[1] }}</span>
            </div>
            <div class="col bg_card pl-0 pr-0"></div>
            <div class="col bgWhite cell_padding pl-0 pr-0">
              <span class="xc4">{{ numbersAll[4] }}</span>
            </div>
            <div class="col bg_card pl-0 pr-0"></div>
            <div class="col bgWhite cell_padding pl-0 pr-0">
              <span class="xc7">{{ numbersAll[7] }}</span>
            </div>
            <div class="col bg_card pl-0 pr-0"></div>
            <div class="col bgWhite cell_padding pl-0 pr-0">
              <span class="xc10">{{ numbersAll[10] }}</span>
            </div>
            <div class="col bg_card pl-0 pr-0"></div>
            <div
              class="col bgWhite border_top_card round10_sag_alt cell_padding pl-0 pr-0"
            >
              <span class="xc14">{{ numbersAll[14] }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-5 mb-5 text-center">
        <a href="#" @click="degistir" class="">
          <img :src="refresh_img" width="60" />
        </a>
        <div>Değiştir</div>
      </div>

      <div class="mt-5 text-center">
        <a
          href="#"
          @click="continues"
          class="btn btn-lg devam_btn font_semibold px-4 py-2 form-control"
          >{{ text_card_devam }}</a
        >
      </div>
    </div>
    <div class="text-center login_logo">
      <img width="160" :src="firma_logo" alt="" />
    </div>
  </div>
</template>

<script>
import turkish from "@/assets/tr-flag.png";
import english from "@/assets/en-flag.png";

import refresh_beyaz from "@/assets/refresh-beyaz.png";
import refresh_siyah from "@/assets/refresh-siyah22.png";

import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, child, get } from "firebase/database";

const config = {
  apiKey: "AIzaSyBak4gv95O20CXuxAZbr2kPjfEoCueLm3Q",
  authDomain: "tombalabiz.firebaseapp.com",
  databaseURL: "https://tombalabiz-default-rtdb.firebaseio.com",
  projectId: "tombalabiz",
  storageBucket: "tombalabiz.appspot.com",
  messagingSenderId: "225198155208",
  appId: "1:225198155208:web:e697d08f91c323dec5ccfd",
};

const f_app = initializeApp(config);
const db = getDatabase(f_app);
const dbRef = ref(db);

const myCss = (mss) => import("@/styles/" + mss + ".css");

export default {
  data() {
    return {
      language: "tr",
      flag: turkish,
      company: "",
      email: "",
      uid: "",
      name: "",
      numbersAll: [],
      numbersOpen: [],
      numbersMy: [],

      text_card_sec: "",
      text_card_change: "",
      text_card_devam: "",

      tombala_logo: "",
      firma_logo: "",
      refresh_type: "beyaz",
      refresh_img: refresh_beyaz,
    };
  },

  async created() {
    let res = await import("@/translate/" + this.$route.meta.company + ".json");
    let res2 = JSON.stringify(res);
    let translate = JSON.parse(res2);

    let translate_icerik = translate.tr;

    this.text_card_sec = translate_icerik.card_sec;
    this.text_card_change = translate_icerik.card_change;
    this.text_card_devam = translate_icerik.card_devam;

    this.tombala_logo = translate_icerik.tombala_logo;
    this.firma_logo = translate_icerik.firma_logo;
    this.refresh_type = translate_icerik.refresh_type;

    if (this.refresh_type == "siyah") {
      this.refresh_img = refresh_siyah;
    } else {
      this.refresh_img = refresh_beyaz;
    }
  },

  methods: {
    getCompany() {
      this.company = this.$route.meta.company;
      console.log(this.$route.meta.company);
      myCss(this.$route.meta.company);

      let nRef = this.company + "/users/" + this.uid + "/allNumbers";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            const data = snapshot.val();

            data.forEach((element) => {
              this.numbersMy.push(element.key);
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showToast(gelen) {
      this.$bvToast.toast(gelen, {
        title: this.text_login_toast_title,
        autoHideDelay: 3000,
        appendToast: true,
        variant: "danger",
        solid: true,
      });
    },
    changeLang(gelen) {
      if (gelen == "tr") {
        this.flag = turkish;
      } else {
        this.flag = english;
      }
      this.language = gelen;
      this.$store.commit("setLanguage", gelen);
      window.location.reload();
    },
    createNumbers() {
      var arr = [];
      while (arr.length < 15) {
        var r = Math.floor(Math.random() * 100) + 1;
        if (r == 100) {
          r = 99;
        }
        if (arr.indexOf(r) === -1) arr.push(r);
      }

      arr.sort(function (a, b) {
        return a - b;
      });

      this.numbersAll = arr;
    },
    degistir() {
      this.numbersAll = [];
      this.createNumbers();
      this.animateCSS(".cardo", "flipInX");
    },
    animateCSS(element, animation, prefix = "animate__") {
      new Promise((resolve) => {
        const animationName = `${prefix}${animation}`;
        const node = document.querySelector(element);
        node.classList.add(`${prefix}animated`, animationName);
        function handleAnimationEnd(event) {
          event.stopPropagation();
          node.classList.remove(`${prefix}animated`, animationName);
          resolve("Animation ended");
        }
        node.addEventListener("animationend", handleAnimationEnd, {
          once: true,
        });
      });
    },
    continues() {
      let secmis = 0;

      let nRef = this.company + "/users/" + this.uid + "/allNumbers";
      get(child(dbRef, nRef))
        .then((snapshot) => {
          if (snapshot.exists()) {
            //const data = snapshot.val();
            //console.log("seçmişşşşşşşşşşşşşş");
            secmis = 1;
          }
        })
        .then(() => {
          if (secmis == 0) {
            if (this.numbersMy.length == 0) {
              let i = 0;
              this.numbersAll.forEach((e) => {
                let cin = 0;
                if (i == 0 || i == 3 || i == 6 || i == 9 || i == 11) {
                  cin = 1;
                } else if (i == 2 || i == 5 || i == 8 || i == 12 || i == 13) {
                  cin = 2;
                } else {
                  cin = 3;
                }

                let giden = {
                  cinko: cin,
                  number: e,
                  opened: 0,
                };

                set(
                  ref(
                    db,
                    this.company + "/users/" + this.uid + "/allNumbers/" + i
                  ),
                  giden
                );

                i++;
              });
            }
          }
        })
        .then(() => {
          this.$router.push("/" + this.company + "/game");
        })

        .catch((error) => {
          console.error(error);
        });
    },
  },

  mounted() {
    this.language = this.$store.getters.getLanguage;
    this.email = this.$store.getters.getEmail;
    this.uid = this.$store.getters.getUid;
    this.name = this.$store.getters.getName;

    console.log("ss");

    this.getCompany();
    this.createNumbers();

    if (this.language == "tr") {
      this.flag = turkish;
    } else {
      this.flag = english;
    }

    let nRef = this.company + "/users/" + this.uid + "/allNumbers";
    get(child(dbRef, nRef))
      .then((snapshot) => {
        if (snapshot.exists()) {
          //const data = snapshot.val();
          //console.log("seçmişşşşşşşşşşşşşş");
          this.$router.push("/" + this.company + "/game");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>
